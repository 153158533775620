import {AxiosInstance} from "axios";
import {AddOrderDayPayload, OrderDayDto} from "../types/AdminApiTypes";
import {AdminService} from "./AdminService";

export class OrderDayAdminService extends AdminService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/order-days')
  }

  add = (payload: AddOrderDayPayload): Promise<number> => {
    return this.httpClient.post('', payload)
  }

  remove = (id: number): Promise<any> => {
    return this.httpClient.delete(`/${id}`);
  }

  getById = (id: number): Promise<OrderDayDto> => {
    return this.httpClient.get(`/${id}`)
  }

  getAllFromToday = (): Promise<OrderDayDto[]> => {
    return this.httpClient.get('')
  }

}

