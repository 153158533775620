import {Button} from "semantic-ui-react";
import React from "react";

interface Props {
  onClick: () => any,
  disabled: boolean,
  text?: String,
  loading?: boolean,
}

export function NextButton(props: Props) {
  const text = props.text ?? "Weiter";
  return (
      <Button loading={props.loading} onClick={props.onClick} color={'red'} disabled={props.disabled} content={text}/>
  )
}
