import {useState} from "react";
import {OrderDay, Timeslot} from "../../../../customer/types/CustomerApiTypes";

export type Configuration = {
  orderDays: OrderDay[];
  initialState: State;
}

type ValidState = {
  selectedOrderDayId: number,
  selectedTimeslotId: number,
}

type InvalidState = {
  selectedOrderDayId?: number,
  selectedTimeslotId?: number,
}

export type State = ValidState | InvalidState;

export type ValidTimeslotFormData = {
  setSelectedOrderDay: (orderDayId: number) => void;
  setSelectedTimeslot: (timeslotId: number) => void;
  availableOrderDays: OrderDay[];
  selectedOrderDay: OrderDay;
  selectedTimeslot: Timeslot;
  resetSelectedTimeslot: () => any;
  isValid: true;
}

export type InvalidTimeslotFormData = {
  setSelectedOrderDay: (orderDayId: number) => void;
  setSelectedTimeslot: (timeslotId: number) => void;
  availableOrderDays: OrderDay[];
  selectedOrderDay?: OrderDay;
  selectedTimeslot?: Timeslot;
  resetSelectedTimeslot: () => any;
  isValid: false;
}

export type TimeslotFormData = ValidTimeslotFormData | InvalidTimeslotFormData;

export function useTimeslotFormData(config: Configuration): TimeslotFormData {

  const [state, setState] = useState<State>(config.initialState);

  function findSelectedOrderDay(): OrderDay | undefined {
    return config.orderDays.find(orderDay => Number(orderDay.id) === Number(state.selectedOrderDayId));
  }

  function findSelectedTimeslot(): Timeslot | undefined {
    return findSelectedOrderDay()?.slots.find(slot => Number(slot.id) === state.selectedTimeslotId);
  }

  function setSelectedOrderDay(selectedOrderDayId: number) {
    if (Number(selectedOrderDayId) === Number(state.selectedOrderDayId)) return;
    setState(prevState => ({...prevState, selectedOrderDayId}));
  }

  function setSelectedTimeslot(selectedTimeslotId: number) {
    if (Number(selectedTimeslotId) === Number(state.selectedTimeslotId)) return;
    setState(prevState => ({...prevState, selectedTimeslotId}))
  }

  function resetSelectedTimeslot() {
    setState(prevState => ({...prevState, selectedTimeslotId: undefined}));
  }

  function isValid(state: State): state is ValidState {
    return state.selectedTimeslotId !== undefined && state.selectedOrderDayId !== undefined;
  }

  function getCommonProps() {
    return {availableOrderDays: config.orderDays, setSelectedTimeslot, setSelectedOrderDay, resetSelectedTimeslot};
  }

  if (isValid(state)) {
    return {...getCommonProps(), selectedOrderDay: findSelectedOrderDay()!!, selectedTimeslot: findSelectedTimeslot()!!, isValid: true}
  } else {
    return {...getCommonProps(), selectedOrderDay: findSelectedOrderDay(), selectedTimeslot: findSelectedTimeslot(), isValid: false}
  }

}
