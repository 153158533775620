import {HttpClient, Params} from "./HttpClient";
import {AxiosInstance} from "axios";

export class AxiosHttpClient implements HttpClient {

  constructor(private axiosInstance: AxiosInstance, private basePath: string = "") {
  }

  get<T>(url: string, params?: Params): Promise<T> {
    return this.axiosInstance.get(this.withBasePath(url), {params}).then(response => response.data)
  }

  delete<T>(url: string): Promise<T> {
    return this.axiosInstance.delete(this.withBasePath(url)).then(response => response.data)
  }

  post<T>(url: string, data: any): Promise<T> {
    return this.axiosInstance.post(this.withBasePath(url), data).then(response => response.data)
  }

  put<T>(url: string, data: any): Promise<T> {
    return this.axiosInstance.put(this.withBasePath(url), data).then(response => response.data)
  }

  private withBasePath(url: string): string {
    return `${this.basePath}/${url}`.replace(/\/+/g, "/")
  }

}
