import {Form} from "semantic-ui-react";
import React from "react";
import {DateInput, DateSetter, NullableDate} from "./DateInput";


export interface DateRangeFormProps {
  setStart: DateSetter;
  setEnd: DateSetter;
  start: NullableDate;
  end: NullableDate;
}

export function DateRangeForm(props: DateRangeFormProps) {

  const {setStart, setEnd, start, end} = props;

  return (
      <Form>
        <Form.Field>
          <DateInput date={start} setDate={setStart} max={end} clearable={true}/>
        </Form.Field>
        <Form.Field>
          <DateInput date={end} setDate={setEnd} min={start} clearable={true}/>
        </Form.Field>
      </Form>
  )

}
