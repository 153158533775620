import {Client, StompHeaders, StompSubscription} from "@stomp/stompjs";

export class StompClient {

  private stompClient?: Client;

  constructor(private url: string, private headers?: StompHeaders) {
  }

  connect(accessToken: string): Promise<StompConnection> {
    const client = new Client({
      brokerURL: `${this.url}?access_token=${accessToken}`,
      connectHeaders: this.headers,
      reconnectDelay: undefined,
      debug: console.log,
    });
    client.activate();
    this.stompClient = client;
    return new Promise((resolve, reject) => {
      try {
        client.onConnect = () => {
          resolve(new StompConnection(client));
        };
      } catch (error) {
        reject(error);
      }
    })
  };

}

export class StompConnection {

  constructor(private stompClient: Client) {
  }

  disconnect = (): Promise<void> => {
    if (!this.stompClient.active) throw new Error('Already disconnected.');
    return this.stompClient.deactivate();
  }

  subscribe<T>(topic: string, handleMessage: (message: T) => void): StompSubscription {
    if (!this.stompClient.active) throw new Error("Already disconnected.");
    return this.stompClient.subscribe(topic, (message) => {
      const body = JSON.parse(message.body);
      handleMessage(body);
    });
  }

}

export interface StompHeader {
  name: string;
  value: string;
}
