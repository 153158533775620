import {useHistory} from "react-router";
import {ProductForm, ProductFormData} from "./ProductForm";
import {Button, Modal} from "semantic-ui-react";
import React from "react";
import {ProductDto, ProductUpdateDto} from "../../types/AdminApiTypes";


interface Props {
  product: ProductDto;
  update: (id: number, product: ProductUpdateDto) => Promise<any>;
}

export function UpdateProductModal(props: Props) {

  const history = useHistory();

  const {product} = props;

  const initialFormData: ProductFormData = {
    sortIndex: product.sortIndex,
    name: product.name,
    price: product.price,
    active: product.active,
    availableUnits: product.availableUnits,
    allergens: product.allergens
  }


  function submit(formData: ProductFormData) {
    return props.update(props.product.id, formData);
  }

  return (
      <Modal open={true} onClose={history.goBack}>
        <Modal.Header>Produkt bearbeiten</Modal.Header>
        <Modal.Content>
          <ProductForm formData={initialFormData} submit={submit}/>
        </Modal.Content>
        <Modal.Actions>
          <Button
              content="Abbrechen"
              onClick={history.goBack}
              negative={true}
          />
        </Modal.Actions>
      </Modal>
  )
}
