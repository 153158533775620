import {Card} from "semantic-ui-react";
import React from "react";
import {OrderActionButtons} from "./OrderActionButtons";
import {EnhancedOrder} from "../../hooks/UseOrdersState";
import {OrderLabels} from "./OrderLabels";
import {OptionalComment} from "./orderview/OptionalComment";
import {OrderItemList} from "./OrderItemList";
import {formatPrice} from "../../../common/components/order-form/common/ProductSelectionForm";
import {OrderDto} from "../../types/AdminApiTypes";
import {PackagingOptionLabel} from "./PackagingOptionLabel";

interface OrderProps {
  order: EnhancedOrder;
  readonly?: boolean;
}

export function OrderCard(props: OrderProps) {
  const order = props.order;
  const {customer} = order;

  function getColor() {
    if (order.status === "DONE") return "green";
    if (order.status === "CANCELED") return "red";
    return "grey";
  }

  return (
      <Card raised={true} color={getColor()}>
        <Card.Content>
          <Card.Header>
            {order.id} - {order.type} <OrderLabels order={order}/>
          </Card.Header>
          <Card.Meta>{order.timeslot.formattedStart} - {order.timeslot.formattedEnd}</Card.Meta>
          {customer.lastName}, {customer.firstName} <br/>
          {customer.emailAddress} <br/>
          {customer.phoneNumber} <br/>
          <OrderItemList orderItems={order.orderItems}/>
          <OptionalComment value={order.productsComment} includeLineBreak={true}/>
          <PackagingOptionLabel packagingOption={order.packagingOption} amount={order.itemsCount}/> <br/>
          <strong>{order.formattedTotalAmount}€ </strong>
          <OptionalDeliveryFeeInfo order={order}/>
          <br/>
          <small>Bestellt am: {order.formattedOrderDate}</small>
          {order.type === "DELIVERY" && (
              <React.Fragment>
                <br/>
                {order.deliveryDetails?.address?.street} - {order.deliveryDetails?.address?.zipcode} <br/>
                <OptionalComment value={order.deliveryDetails?.address.comment}/>
              </React.Fragment>
          )}
        </Card.Content>
        {!props.readonly && (
            <Card.Content extra textAlign={"right"}>
              <OrderActionButtons order={order}/>
            </Card.Content>
        )}
      </Card>
  )
}

export function OptionalDeliveryFeeInfo(props: { order: OrderDto }) {
  const fee = props.order.deliveryDetails?.fee
  if (!fee) return null
  return <small>(inkl. {formatPrice(fee)} Lieferkosten)</small>
}