import {Button, Grid, Table} from "semantic-ui-react";
import React from "react";
import {ModifiableProductAmount} from "../hooks/UseProductsFormData";

interface TableProps {
  productAmounts: ModifiableProductAmount[];
  isReadonly: boolean;
}

export function ProductSelectionTable(props: TableProps) {

  const productAmounts = props.isReadonly ? props.productAmounts.filter(pa => pa.amount > 0) : props.productAmounts;

  return (
      <Table unstackable={true} size={"small"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6} textAlign={"left"} content={"Produkt"}/>
            <Table.HeaderCell textAlign={"center"} content={"Preis"}/>
            <Table.HeaderCell textAlign={"center"} content={"Menge"}/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {productAmounts.map(productAmount => (
              <ProductTableRow key={productAmount.product.id} productAmount={productAmount} isReadonly={props.isReadonly}/>
          ))}
        </Table.Body>
      </Table>
  )

}

interface RowProps {
  productAmount: ModifiableProductAmount;
  isReadonly: boolean;
}

function ProductTableRow(props: RowProps) {

  const {productAmount} = props;
  const {product, amount, setAmount} = productAmount;

  function getAllergens(): string {
    if (!product.allergens) return "";
    return `(${product.allergens})`;
  }

  function isSoldOut(): boolean {
    return Number(product.availableUnits) === 0;
  }

  return (
      <Table.Row disabled={isSoldOut()}>
        <Table.Cell textAlign={"left"} content={`${product.name} ${getAllergens()}`} width={props.isReadonly ? 12 : 9}/>
        <Table.Cell textAlign={"center"} content={`${product.formattedPrice} €`} width={3}/>
        {!props.isReadonly && !isSoldOut() && (
            <Table.Cell textAlign={"center"} width={props.isReadonly ? 1 : 4}>
              <Grid columns={"equal"} stackable={true} verticalAlign={"middle"} textAlign={"center"} padded={false} reversed={"mobile"}>
                <Grid.Column width={6}>
                  <Button
                      circular={true}
                      icon={"minus"}
                      color={"red"}
                      disabled={Number(amount) === 0}
                      onClick={() => setAmount(amount - 1)}
                  />
                </Grid.Column>
                <Grid.Column width={4}>{amount}</Grid.Column>
                <Grid.Column width={6}>
                  <Button
                      circular={true}
                      icon={'add'}
                      color={'green'}
                      disabled={Number(amount) === Number(product.availableUnits)}
                      onClick={() => setAmount(amount + 1)}
                  />
                </Grid.Column>
              </Grid>
            </Table.Cell>
        )}
        {isSoldOut() && (
            <Table.Cell textAlign={"center"} content={"Ausverkauft"}/>
        )}
        {props.isReadonly && (
            <Table.Cell textAlign={"center"} content={amount}/>
        )}
      </Table.Row>
  )
}
