import React from "react";
import {Route, Switch} from "react-router";
import {OrderProgressIndicator} from "../common/OrderProgressIndicator";
import {Divider} from "semantic-ui-react";
import {ConditionalRoute} from "../common/ConditionalRoute";
import {ProductsFormPage} from "../common/ProductsFormPage";
import {CustomerFormPage} from "../common/CustomerFormPage";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";
import {CustomerFormData} from "../hooks/UseCustomerFormData";
import {ProductsFormData} from "../hooks/UseProductsFormData";
import {OrderTypeFormAndTimeslotFormAndAddressFormPage} from "./OrderTypeFormAndTimeslotFormAndAddressFormPage";
import {AddressFormData} from "../hooks/UseAddressFormData";
import {DeliverySummaryPage} from "./DeliverySummaryPage";
import {DeliveryCurrentlyNotAvailableMessage} from "./DeliveryCurrentlyNotAvailableMessage";
import {OrderType} from "../../../../admin/pages/OrdersPage";
import {SubmitStatus} from "../types/SubmitStatus";

export interface DeliveryOrderFormProps {
  basePath: string;
  navigateTo: (to: string) => any;
  selectOrderType: (orderType: OrderType) => any;
  submitStatus: SubmitStatus;
  timeslotFormData: TimeslotFormData;
  customerFormData: CustomerFormData;
  productsFormData: ProductsFormData;
  deliveryAddressFormData: AddressFormData;
  submit: () => any;
}

export function DeliveryOrderForm(props: DeliveryOrderFormProps) {

  const {
    submitStatus,
    timeslotFormData,
    customerFormData,
    productsFormData,
    submit,
    deliveryAddressFormData,
    basePath,
    navigateTo,
    selectOrderType
  } = props;

  if (timeslotFormData.availableOrderDays.length < 1) {
    return <DeliveryCurrentlyNotAvailableMessage selectOrderType={selectOrderType}/>
  }

  function getTotalAmount(): number {
    return productsFormData.getBasePrice() + getDeliveryFee();
  }

  function getDeliveryFee(): number {
    const deliveryArea = props.deliveryAddressFormData.area;
    if (!deliveryArea) return 0;
    if (!deliveryArea.freeDeliveryThreshold) {
      return deliveryArea.deliveryFee;
    } else if (productsFormData.getBasePrice() < deliveryArea.freeDeliveryThreshold) {
      return deliveryArea.freeDeliveryThreshold - productsFormData.getBasePrice()
    } else {
      return 0.0;
    }
  }

  return (
      <React.Fragment>
        <OrderProgressIndicator
            basePath={basePath}
            submitStatus={submitStatus}
            isTimeslotFormDataValid={timeslotFormData.isValid && deliveryAddressFormData.isValid}
            isCustomerFormDataValid={customerFormData.isValid}
            isProductsFormDataValid={productsFormData.isValid}
        />
        <Divider hidden={true}/>
        <Switch>
          <Route path={basePath} exact={true}>
            <OrderTypeFormAndTimeslotFormAndAddressFormPage
                addressFormData={deliveryAddressFormData}
                timeslotFormData={timeslotFormData}
                selectOrderType={selectOrderType}
                next={() => navigateTo("products")}
            />
          </Route>
          <ConditionalRoute path={`${basePath}/products`} condition={timeslotFormData.isValid} exact={true} redirectPath={basePath}>
            <ProductsFormPage
                productsFormData={productsFormData}
                totalAmount={getTotalAmount()}
                freeDeliveryThreshold={props.deliveryAddressFormData?.area?.freeDeliveryThreshold}
                deliveryFee={getDeliveryFee()}
                next={() => navigateTo("customer")}
                back={() => navigateTo("")}
            />
          </ConditionalRoute>
          <ConditionalRoute path={`${basePath}/customer`} condition={productsFormData.isValid && timeslotFormData.isValid} redirectPath={basePath}>
            <CustomerFormPage
                customerFormData={customerFormData}
                next={() => navigateTo("summary")}
                back={() => navigateTo("products")}
            />
          </ConditionalRoute>
          <ConditionalRoute
              path={`${basePath}/summary`}
              exact={true}
              condition={customerFormData.isValid && productsFormData.isValid && timeslotFormData.isValid}
              redirectPath={basePath}
          >
            <DeliverySummaryPage
                timeslotFormData={timeslotFormData}
                addressFormData={deliveryAddressFormData}
                productsFormData={productsFormData}
                customerFormData={customerFormData}
                totalAmount={getTotalAmount()}
                freeDeliveryThreshold={props.deliveryAddressFormData?.area?.freeDeliveryThreshold}
                deliveryFee={getDeliveryFee()}
                back={() => navigateTo("customer")}
                submitStatus={submitStatus}
                submit={submit}
            />
          </ConditionalRoute>
        </Switch>
        <Divider hidden={true}/>
      </React.Fragment>
  )

}