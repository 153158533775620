import React from "react";
import {Header} from "semantic-ui-react";
import {ProductSelectionForm} from "./ProductSelectionForm";
import {ProductsFormData} from "../hooks/UseProductsFormData";

interface Props {
  productsFormData: ProductsFormData;
  totalAmount: number;
  deliveryFee?: number;
  freeDeliveryThreshold?: number;
}

export function OrderItemsSummary(props: Props) {

  return (
      <React.Fragment>
        <Header as={'h3'}>Produkte</Header>
        <ProductSelectionForm
            productsFormData={props.productsFormData} isReadonly={true} deliveryFee={props.deliveryFee} totalAmount={props.totalAmount}
            freeDeliveryThreshold={props.freeDeliveryThreshold}
        />
      </React.Fragment>
  )

}
