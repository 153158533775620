import React, {useEffect, useState} from "react";
import {Container, Loader} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {Redirect, Route, Switch} from "react-router";
import {CardOrderView} from "../components/orders/orderview/CardOrderView";
import {ServiceOrderView} from "../components/orders/orderview/ServiceOrderView";
import {OrdersState} from "../hooks/UseOrdersState";
import {KitchenOrderView} from "../components/orders/orderview/KitchenOrderView";
import {DeliveryOrderView} from "../components/orders/orderview/DeliveryOrderView";
import {DriverDto} from "../types/AdminApiTypes";
import {OrderEditPage} from "./OrderEditPage";
import {useAdminService} from "../context/AdminContext";
import {useRoutePaths} from "../context/RoutePathsContext";

interface Props {
  ordersState: OrdersState
}

export function OrdersPage(props: Props) {

  const {ordersState} = props;

  const driverService = useAdminService("driverService");
  const [drivers, setDrivers] = useState<DriverDto[]>([]);
  const routePaths = useRoutePaths().employee.orders;

  useEffect(() => {
    driverService.getAll().then(setDrivers);
  }, [driverService]);

  if (!ordersState.initialized) {
    return <Container textAlign={"center"}><Loader active={true}/></Container>
  }

  function getOrderViewProps() {
    return {ordersState, drivers}
  }

  return (
      <Container>
        <Divider hidden={true}/>
        <Switch>
          <Route path={routePaths.cards()} render={() => <CardOrderView {...getOrderViewProps()}/>}/>
          <Route path={routePaths.service()} render={() => <ServiceOrderView {...getOrderViewProps()}/>}/>
          <Route path={routePaths.kitchen()} render={() => <KitchenOrderView {...getOrderViewProps()}/>}/>
          <Route path={routePaths.delivery()} render={() => <DeliveryOrderView {...getOrderViewProps()}/>}/>
          <Route path={routePaths.order.root()}>
            {props => {
              const orderId = props.match!!.params.id;
              const order = ordersState.getOrder(orderId);
              return <OrderEditPage order={order} submitCallback={ordersState.refresh}/>
            }}
          </Route>
          <Redirect to={routePaths.cards()}/>
        </Switch>
        <Divider hidden={true}/>
      </Container>
  )
}

export type OrderType = "PICKUP" | "DELIVERY";
