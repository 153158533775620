import {AxiosInstance} from "axios";
import {ProductDto, ProductUpdateDto} from "../types/AdminApiTypes";
import {AdminService} from "./AdminService";

export class ProductAdminService extends AdminService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/products')
  }

  getAll = (): Promise<ProductDto[]> => {
    return this.httpClient.get('')
  }

  update = (id: number, product: ProductUpdateDto): Promise<void> => {
    return this.httpClient.put(`/${id}`, product);
  }

  create = (product: ProductUpdateDto): Promise<number> => {
    return this.httpClient.post('', product)
  }

  deleteProduct = (id: number): Promise<void> => {
    return this.httpClient.delete(`/${id}`)
  }

}
