import {CardGroup} from "semantic-ui-react";
import {OrderCard} from "../OrderCard";
import React from "react";
import {OrderViewProps} from "./OrderViewProps";
import {OrderViewMenu} from "../../OrderViewMenu";

export function CardOrderView(props: OrderViewProps) {
  return (
      <React.Fragment>
        <OrderViewMenu {...props}/>
        <CardGroup centered={true} stackable={true}>
          {props.ordersState.orders.map(order => <OrderCard key={order.id} order={order}/>)}
        </CardGroup>
      </React.Fragment>
  )
}
