import {Dropdown, DropdownItemProps, Form, Input, TextArea} from "semantic-ui-react";
import React from "react";
import {AddressFormData} from "../hooks/UseAddressFormData";

interface Props {
  addressFormData: AddressFormData;
  isReadonly: boolean;
}

export function AddressForm(props: Props) {

  const {addressFormData, isReadonly} = props;

  const zipcodeOptions: DropdownItemProps[] = addressFormData.availableZipcodes.map(zipcode => ({
    key: zipcode,
    text: zipcode,
    value: zipcode,
  }));

  return (
      <Form>
        <Form.Field required={!isReadonly}>
          <label>Straße und Hausnummer</label>
          <Input
              placeholder={"Straße und Hausnummer"}
              readOnly={isReadonly}
              value={addressFormData.street ?? ""}
              onChange={event => addressFormData.setStreet(event.target.value)}
          />
        </Form.Field>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <label>Stadt</label>
            <Input
                placeholder={"Stadt"}
                readOnly={isReadonly}
                value={"Wien"}
                disabled={true}
            />
          </Form.Field>
          <Form.Field required={!isReadonly}>
            <label>Postleitzahl</label>
            <Dropdown
                disabled={isReadonly}
                options={zipcodeOptions}
                search={(options, value) => options.filter(option => option.value?.toString().startsWith(value))}
                fluid={true}
                selection={true}
                placeholder={"Postleitzahl"}
                noResultsMessage={"Keine Ergebnisse gefunden."}
                value={addressFormData.zipcode ?? ""}
                onChange={(_, data) => addressFormData.setZipcode(String(data.value))}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Bemerkungen</label>
          <TextArea
              readOnly={isReadonly}
              placeholder={"Bemerkungen..."}
              value={addressFormData.comment ?? ""}
              onChange={event => addressFormData.setComment(event.target.value)}
              rows={4}
          />
        </Form.Field>
      </Form>
  )

}
