import React from "react";
import {Header} from "semantic-ui-react";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";

interface Props {
  timeslotFormData: TimeslotFormData;
}

export function DeliveryDataSummary(props: Props) {
  const {timeslotFormData} = props;

  if (!timeslotFormData.isValid) {
    return <div>Unvollständige Angaben.</div>
  }

  const date = timeslotFormData.selectedOrderDay.formattedDate;
  const start = timeslotFormData.selectedTimeslot.formattedStart;
  const end = timeslotFormData.selectedTimeslot.formattedEnd;

  return (
      <React.Fragment>
        <Header as={'h3'}>Lieferung</Header>
        <div>Am <strong>{date}</strong> zwischen <strong>{start} - {end}</strong> Uhr.</div>
      </React.Fragment>
  )

}
