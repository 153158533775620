import {CustomerService} from "./CustomerService";
import {AxiosInstance} from "axios";
import {PackagingOption} from "../types/CustomerApiTypes";

export class PackagingOptionsCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/packaging-options')
  }

  getPackagingOptions = (): Promise<PackagingOption[]> => {
    return this.httpClient.get('')
  }

}
