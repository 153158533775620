import {Step} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import React from "react";
import {SubmitStatus} from "../types/SubmitStatus";
import {Responsive} from "../../lib/Responsive";


interface Props {
  basePath: string;
  submitStatus: SubmitStatus;
  isTimeslotFormDataValid: boolean;
  isProductsFormDataValid: boolean;
  isCustomerFormDataValid: boolean;
}

export function OrderProgressIndicator(props: Props) {

  const {submitStatus, basePath, isTimeslotFormDataValid, isProductsFormDataValid, isCustomerFormDataValid} = props;

  const allDisabled = submitStatus === SubmitStatus.Success;

  return (
      <Responsive biggerOrEqual={"tablet"}>
        <Step.Group ordered fluid={true} widths={4} size={"small"}>
          <Step as={NavLink} exact={true} to={basePath} completed={isTimeslotFormDataValid} disabled={allDisabled}>
            <Step.Content>
              <Step.Title>Termin</Step.Title>
            </Step.Content>
          </Step>
          <Step
              as={NavLink} exact={true} to={`${basePath}/products`} completed={isProductsFormDataValid}
              disabled={allDisabled || !isTimeslotFormDataValid}
          >
            <Step.Content>
              <Step.Title>Speisen</Step.Title>
            </Step.Content>
          </Step>
          <Step
              as={NavLink} exact={true} to={`${basePath}/customer`} completed={isCustomerFormDataValid}
              disabled={allDisabled || !isProductsFormDataValid || !isTimeslotFormDataValid}
          >
            <Step.Content>
              <Step.Title>Daten</Step.Title>
            </Step.Content>
          </Step>
          <Step
              as={NavLink} exact={true} to={`${basePath}/summary`} completed={submitStatus === SubmitStatus.Success}
              disabled={allDisabled || !isCustomerFormDataValid || !isTimeslotFormDataValid || !isProductsFormDataValid}
          >
            <Step.Content>
              <Step.Title>Checkout</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      </Responsive>
  )
}
