import {Dropdown, DropdownItemProps, DropdownProps} from "semantic-ui-react";
import React, {SyntheticEvent} from "react";
import {DriverDto} from "../../types/AdminApiTypes";


function getDropdownOptions(drivers: DriverDto[]): DropdownItemProps[] {
  return drivers.map(driver => ({
    key: driver.id,
    text: driver.nickname,
    value: driver.id,
    description: driver.name,
  }));
}

interface Props {
  drivers: DriverDto[];
  selectedDriverId: number | undefined;
  onChange: (driverId: number | undefined) => void;
  disabled?: boolean;
}

export function DriverDropdown(props: Props) {

  const notDriverValue = "none";

  const noDriver: DropdownItemProps = {
    key: notDriverValue,
    text: "-",
    value: notDriverValue,
    description: "Kein Fahrer"
  }

  function onChange(event: SyntheticEvent<HTMLElement>, data: DropdownProps) {
    const driverId = data.value;
    const updateValue = driverId === notDriverValue ? undefined : Number(driverId);
    props.onChange(updateValue);
  }

  return (
      <Dropdown
          disabled={props.disabled}
          placeholder={"-"}
          pointing={"right"}
          value={props.selectedDriverId ?? notDriverValue}
          options={[noDriver, ...getDropdownOptions(props.drivers)]}
          onChange={onChange}
      />
  )
}

interface MultipleProps {
  drivers: DriverDto[];
  selectedDriverIds: number[];
  onChange: (driverIds: number[]) => void;
}

export function DriverMultipleDropdown(props: MultipleProps) {

  function onChange(event: SyntheticEvent<HTMLElement>, data: DropdownProps) {
    const driverIds = data.value;
    props.onChange(driverIds as number[]);
  }

  return (
      <Dropdown
          fluid={true}
          placeholder={"Fahrer filtern"}
          selection={true}
          multiple={true}
          value={props.selectedDriverIds}
          options={getDropdownOptions(props.drivers)}
          onChange={onChange}
      />
  )

}
