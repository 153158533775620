import {AxiosInstance} from "axios";
import {CustomerService} from "./CustomerService";
import {Shop} from "../types/CustomerApiTypes";

export class ShopCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/shop')
  }

  getShop = (): Promise<Shop> => {
    return this.httpClient.get('')
  }

}
