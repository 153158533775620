import {EnhancedOrder} from "../../hooks/UseOrdersState";
import {Icon, Popup} from "semantic-ui-react";
import React from "react";

export function OrderLabels(props: { order: EnhancedOrder }) {
  const {order} = props;
  return (
      <React.Fragment>
        {order.isPast() && (
            <Popup trigger={<Icon name={"archive"}/>}>Vergangen</Popup>
        )}
        {order.status === "DONE" && (
            <Popup trigger={<Icon name={"check"} color={"green"}/>}>Abgeschlossen</Popup>
        )}
        {order.status === "CANCELED" && (
            <Popup trigger={<Icon color={"red"} name={"x"}/>}>Storniert</Popup>
        )}
        {order.isNew() && (
            <Popup trigger={<Icon name={"bell"} color="teal"/>}>Neu</Popup>
        )}
      </React.Fragment>
  )
}
