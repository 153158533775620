import {useState} from "react";

const LOCAL_STORAGE_BASE_NAME = "velani_order_system_employee_app"

export function useLocalStorageDate(key: string, defaultValue: Date | null) {
    return useLocalStorage(
        key,
        defaultValue,
        (value) => value?.toDateString() ?? null,
        (value) => new Date(value),
    )
}

export function useLocalStorage<T>(
    key: string,
    defaultValue: T,
    serialize?: (value: T) => string | null,
    deserialize?: (value: string) => T
): [T, (value: T) => void] {

    function getKey(): string {
        return `${LOCAL_STORAGE_BASE_NAME}:${key}`
    }

    function getStoredItem(): T | undefined {
        const value = localStorage.getItem(getKey())
        if (value == null) {
            return undefined
        } else {
            return deserialize === undefined || value === "null" ? JSON.parse(value) : deserialize(value)
        }
    }

    function getInitialValue(): T {
        const storedValue = getStoredItem()
        if (storedValue !== undefined) {
            return storedValue
        } else {
            return defaultValue
        }
    }

    const [value, setValue] = useState<T>(getInitialValue())

    function updateValue(value: T) {
        const serializedValue = serialize === undefined || value === null ? JSON.stringify(value) : serialize(value)
        localStorage.setItem(getKey(), serializedValue ?? "null")
        setValue(value)
    }

    return [value, updateValue]

}
