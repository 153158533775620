import {Grid} from "semantic-ui-react";
import React from "react";

type Props = {
  nextButton: JSX.Element;
  backButton?: JSX.Element;
} | {
  nextButton?: JSX.Element;
  backButton: JSX.Element;
}


export function NavigationButtonGrid(props: Props) {

  const {nextButton, backButton} = props;

  function getColumnCount(): 1 | 2 {
    return !nextButton || !backButton ? 1 : 2;
  }

  return (
      <Grid columns={getColumnCount()} stackable={false}>
        {backButton && (
            <Grid.Column width={4} textAlign={"left"}>
              {backButton}
            </Grid.Column>
        )}
        {nextButton && (
            <Grid.Column width={backButton ? 12 : 16} textAlign={"right"}>
              {nextButton}
            </Grid.Column>
        )}
      </Grid>
  )

}
