import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {AdminMenu} from "./AdminMenu";
import {ProductsPage} from "../pages/ProductsPage";
import {OrderDaysPage} from "../pages/OrderDaysPage";
import {OrdersPage} from "../pages/OrdersPage";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useOrdersState} from "../hooks/UseOrdersState";
import {Link} from "react-router-dom";
import {useOrderEvents} from "../hooks/UseOrderEvents";
import {useAdminService} from "../context/AdminContext";
import {useRoutePaths} from "../context/RoutePathsContext";
import {SettingsPage} from "../pages/SettingsPage";

interface Props {
  logout: () => void;
}

export function AdminLayout(props: Props) {

  const orderService = useAdminService("orderService");
  const ordersState = useOrdersState(orderService);
  const routePaths = useRoutePaths().employee

  useOrderEvents(event => {
        ordersState.refresh();
        const toastContent = (
            <React.Fragment>
              {event.formattedTimestamp}
              <Link to={routePaths.orders.order.edit.root(String(event.orderId))}> - Neue Bestellung</Link>
            </React.Fragment>
        )
        toast(toastContent, {type: "default", autoClose: false});
      }
  )

  return (
      <React.Fragment>
        <AdminMenu logout={props.logout}/>
        <Switch>
          <Route path={routePaths.orders.root()} render={() => <OrdersPage ordersState={ordersState}/>}/>
          <Route path={routePaths.products.root()} component={ProductsPage}/>
          <Route path={routePaths.orderDays.root()} component={OrderDaysPage}/>
          <Route path={routePaths.settings.root()} component={SettingsPage}/>
          <Redirect to={routePaths.orders.root()}/>
        </Switch>
        <ToastContainer/>
      </React.Fragment>
  )

}
