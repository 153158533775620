import React, {ReactNode, useContext} from "react";

// @ts-ignore
export const ReactRoutePathsContext = React.createContext<RoutePaths>(null)

type LazyPath = () => string;

export interface RoutePaths {
  employee: {
    root: LazyPath
    orders: {
      root: LazyPath,
      cards: LazyPath,
      service: LazyPath,
      kitchen: LazyPath,
      delivery: LazyPath,
      order: {
        root: (orderId?: string) => string,
        edit: {
          root: (orderId?: string) => string,
          delivery: (orderId?: string) => string,
          pickup: (orderId?: string) => string,
        }
      },
    },
    products: {
      root: LazyPath,
      new: LazyPath,
      product: {
        root: (productId?: string) => string;
        edit: (productId?: string) => string;
      },
    },
    orderDays: {
      root: LazyPath,
    },
    settings: {
      root: LazyPath,
    }
  },
}

interface Props {
  routePaths: RoutePaths;
  children: ReactNode;
}

export function RoutePathsContextProvider(props: Props) {

  return (
      <ReactRoutePathsContext.Provider value={props.routePaths} children={props.children}/>
  )

}

export function useRoutePaths() {
  return useContext(ReactRoutePathsContext)
}

function createRoutePaths(basePath?: string): RoutePaths {
  function createLazyPath(...components: string[]): LazyPath {
    return () => `${basePath ?? "/"}${components.join("/")}`
  }

  function createEmployeePath(...components: string[]): LazyPath {
    return createLazyPath("admin", ...components)
  }

  return {
    employee: {
      root: createEmployeePath(),
      orders: {
        root: createEmployeePath("orders"),
        cards: createEmployeePath("orders", "cards"),
        service: createEmployeePath("orders", "service"),
        kitchen: createEmployeePath("orders", "kitchen"),
        delivery: createEmployeePath("orders", "delivery"),
        order: {
          root: (id: string = ":id") => createEmployeePath("orders", id)(),
          edit: {
            root: (id: string = ":id") => createEmployeePath("orders", id, "edit")(),
            pickup: (id: string = ":id") => createEmployeePath("orders", id, "edit", "pickup")(),
            delivery: (id: string = ":id") => createEmployeePath("orders", id, "edit", "delivery")(),
          }
        }
      },
      products: {
        root: createEmployeePath("products"),
        new: createEmployeePath("products", "new"),
        product: {
          root: (id: string = ":id") => createEmployeePath("products", id)(),
          edit: (id: string = ":id") => createEmployeePath("products", id, "edit")(),
        }
      },
      orderDays: {
        root: createEmployeePath("order-days")
      },
      settings: {
        root: createEmployeePath("shop")
      }
    },
  }
}

export function createStandaloneRoutePaths(): RoutePaths {
  return createRoutePaths()
}

export function createIntegratedRoutePaths(shop: string): RoutePaths {
  return createRoutePaths(`/${shop}/`)
}
