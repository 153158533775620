import React from "react";
import {Header} from "semantic-ui-react";
import {CustomerForm} from "./CustomerForm";
import {CustomerFormData} from "../hooks/UseCustomerFormData";

interface Props {
  customerFormData: CustomerFormData;
}

export function CustomerSummary(props: Props) {
  return (
      <React.Fragment>
        <Header as={'h3'}>Persönliche Daten</Header>
        <CustomerForm isReadonly={true} formData={props.customerFormData}/>
      </React.Fragment>
  )
}
