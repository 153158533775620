import {KeycloakInstance} from "keycloak-js";

export class AccessQuery {

  constructor(private keycloak: KeycloakInstance) {
  }

  isEmployee() {
    return this.hasRole("employee");
  }

  canEditOrder() {
    return this.hasRole("scope_order_edit");
  }

  canCancelOrder() {
    return this.hasRole("scope_order_cancel");
  }

  canUpdateOrderStatus() {
    return this.hasRole("scope_order_update_status");
  }

  canCreateProduct() {
    return this.hasRole("scope_product_create");
  }

  canUpdateProduct() {
    return this.hasRole("scope_product_update");
  }

  canDeleteProduct() {
    return this.hasRole("scope_product_delete");
  }

  canAddOrderDay() {
    return this.hasRole("scope_order_day_add");
  }

  canUpdateOrderDriver() {
    return this.hasRole("scope_order_update_driver");
  }

  canUpdateShop() {
    return this.hasRole("scope_shop_update")
  }

  private hasRole(role: string) {
    return this.keycloak.hasRealmRole(role);
  }

}
