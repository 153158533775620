import {AxiosInstance} from "axios";
import {CustomerService} from "./CustomerService";
import {DeliveryOrder, PickupOrder} from "../types/CustomerApiTypes";

export class OrderCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/place-order')
  }

  placePickupOrder = (order: PickupOrder): Promise<void> => {
    return this.httpClient.post('/pickup', order)
  }

  placeDeliveryOrder = (order: DeliveryOrder): Promise<void> => {
    return this.httpClient.post('/delivery', order)
  }

}
