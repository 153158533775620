import logo from "../../logo.png";
import React from "react";

export function VelaniLogo() {
  return (
      <div className="ui inverted vertical masthead center aligned segment" style={{background: 'rgba(133, 191, 119, 0.9)'}}>
        <header>
          <a href={"https://www.velani.at"}>
            <div className="ui medium image">
              <img src={logo} alt="Velani"/>
            </div>
          </a>
        </header>
      </div>
  )
}
