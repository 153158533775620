import {OrderItemDto, PackagingOptionData, ProductSnapshotDto} from "../types/AdminApiTypes";
import * as R from "ramda";
import {EnhancedOrder} from "../hooks/UseOrdersState";

type CommonOrderType = "DELIVERY" | "PICKUP" | "MIXED";

export interface ReadyTimeOrderGroup {
  readyTime: Date,
  formattedReadyTime: string;
  orderItemGroups: OrderItemProductGroup[];
  productComments: string[];
  commonOrderType: CommonOrderType;
  orders: EnhancedOrder[],
}

interface PackagingAmount {
  packagingOption: PackagingOptionData;
  amount: number;
}

export interface OrderItemProductGroup {
  product: ProductSnapshotDto;
  accumulatedAmount: number;
  packagingAmounts: PackagingAmount[];
}

interface PackagedOrderItem extends OrderItemDto {
  packagingOption: PackagingOptionData,
}


function groupByPackaging(items: PackagedOrderItem[]): PackagedOrderItem[][] {
  const indexedGroups: { [packagingOptionId: number]: PackagedOrderItem[] } = {};
  for (let item of items) {
    const group = indexedGroups[item.packagingOption.id] ?? [];
    indexedGroups[item.packagingOption.id] = [...group, item];
  }
  return Object.values(indexedGroups);
}

function mapOrderItemsToOrderItemProductGroup(orderItems: PackagedOrderItem[]): OrderItemProductGroup {
  const product = orderItems[0].product;
  const accumulatedAmount = orderItems.reduce((sum, item) => sum + item.amount, 0);
  const groupedByPackaging = groupByPackaging(orderItems)
  const packagingAmounts: PackagingAmount[] = groupedByPackaging.map(group => {
    const accumulatedAmount = group.reduce((sum, item) => sum + item.amount, 0);
    return {
      packagingOption: group[0].packagingOption,
      amount: accumulatedAmount,
    }
  })
  return {product, accumulatedAmount, packagingAmounts}
}

function groupOrderItemsByProduct(orderItems: PackagedOrderItem[]): OrderItemProductGroup[] {
  const groupByProduct = R.groupBy<PackagedOrderItem>(orderItem => orderItem.product.name)
  const productGroups = Object.values(groupByProduct(orderItems));
  const groups = productGroups.map(mapOrderItemsToOrderItemProductGroup);
  return R.sortWith([R.descend(R.prop('accumulatedAmount'))], groups);
}

function accumulateComments(orders: EnhancedOrder[]): string[] {
  return orders.map(order => order.productsComment).filter(comment => comment !== null) as string[];
}

function getCommonOrderType(orders: EnhancedOrder[]): CommonOrderType {
  const firstOrderType = orders[0].type;
  for (let order of orders) {
    if (order.type !== firstOrderType) return "MIXED";
  }
  return firstOrderType;
}

function createReadyTimeGroup(orders: EnhancedOrder[]): ReadyTimeOrderGroup {
  const readyTime = new Date(orders[0].readyTime);
  const orderItems = getOrderItemsWithPackaging(orders)
  return {
    productComments: accumulateComments(orders),
    orderItemGroups: groupOrderItemsByProduct(orderItems),
    formattedReadyTime: orders[0].formattedReadyTime,
    commonOrderType: getCommonOrderType(orders),
    readyTime,
    orders,
  }
}

function getOrderItemsWithPackaging(orders: EnhancedOrder[]): PackagedOrderItem[] {
  return orders.flatMap(order => {
    return order.orderItems.map(item => ({...item, packagingOption: order.packagingOption}))
  })
}

function groupByReadyTime(orders: EnhancedOrder[]): EnhancedOrder[][] {
  const indexedGroups: { [formattedReadyTime: string]: EnhancedOrder[] } = {};
  for (let order of orders) {
    const group = indexedGroups[order.formattedReadyTime] ?? [];
    indexedGroups[order.formattedReadyTime] = [...group, order];
  }
  return Object.values(indexedGroups);
}

export function groupOrdersItemsByOrderReadyTime(orders: EnhancedOrder[]): ReadyTimeOrderGroup[] {
  const orderGroups = groupByReadyTime(orders);
  const readyTimeGroups: ReadyTimeOrderGroup[] = orderGroups.map(createReadyTimeGroup);
  return R.sortBy<ReadyTimeOrderGroup>(a => a.readyTime, readyTimeGroups);
}

export function groupOrdersByProduct(orders: EnhancedOrder[]): OrderItemProductGroup[] {
  const orderItems = getOrderItemsWithPackaging(orders)
  return groupOrderItemsByProduct(orderItems);
}
