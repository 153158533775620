import React, {ReactNode, useContext} from "react";
import {OrderDayCustomerService} from "../services/OrderDayCustomerService";
import {OrderCustomerService} from "../services/OrderCustomerService";
import {ProductsCustomerService} from "../services/ProductsCustomerService";
import {DeliveryCustomerService} from "../services/DeliveryCustomerService";
import {PackagingOptionsCustomerService} from "../services/PackagingOptionsCustomerService";
import {ShopCustomerService} from "../services/ShopCustomerService";


interface RoutePaths {
  root: () => string;
}

export interface CustomerAppContext {
  services: CustomerServices
  components: CustomerComponents
  routePaths: CustomerRoutePaths
}

interface CustomerComponents {
  LogoBanner: React.FunctionComponent;
}

interface CustomerServices {
  orderService: OrderCustomerService,
  orderDayService: OrderDayCustomerService,
  productService: ProductsCustomerService,
  deliveryService: DeliveryCustomerService,
  packagingOptionsService: PackagingOptionsCustomerService,
  shopService: ShopCustomerService,
}

export interface CustomerRoutePaths extends RoutePaths {

}

// @ts-ignore
const ReactCustomerContext = React.createContext<CustomerAppContext>(null)

interface Props {
  context: CustomerAppContext;
  children: ReactNode;
}

export function useCustomerContext(): CustomerAppContext {

  return useContext(ReactCustomerContext);

}

export function useCustomerServices(): CustomerServices {

  const context = useCustomerContext()
  return context.services;

}

export function useCustomerComponents(): CustomerComponents {
  const context = useCustomerContext()
  return context.components
}

export function useCustomerRoutePaths(): CustomerRoutePaths {
  const context = useCustomerContext()
  return context.routePaths
}

export function CustomerAppContextProvider(props: Props) {

  return (
      <ReactCustomerContext.Provider value={props.context} children={props.children}/>
  )

}
