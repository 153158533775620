import {Route, Switch} from "react-router-dom";
import React from "react";
import {OrderTypeAndTimeslotFormPage} from "./OrderTypeAndTimeslotFormPage";
import {ProductsFormPage} from "../common/ProductsFormPage";
import {CustomerFormPage} from "../common/CustomerFormPage";
import {PickupSummaryPage} from "./PickupSummaryPage";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {OrderProgressIndicator} from "../common/OrderProgressIndicator";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";
import {ProductsFormData} from "../hooks/UseProductsFormData";
import {ConditionalRoute} from "../common/ConditionalRoute";
import {PickupCurrentlyNotAvailableMessage} from "./PickupCurrentlyNotAvailableMessage";
import {CustomerFormData} from "../hooks/UseCustomerFormData";
import {OrderType} from "../../../../admin/pages/OrdersPage";
import {SubmitStatus} from "../types/SubmitStatus";

export interface Props {
  basePath: string;
  navigateTo: (to: string) => any;
  submitStatus: SubmitStatus,
  selectOrderType: (orderType: OrderType) => any;
  timeslotFormData: TimeslotFormData;
  productsFormData: ProductsFormData;
  customerFormData: CustomerFormData;
  submit: () => any;
}

export function PickupOrderForm(props: Props) {

  const {submitStatus, timeslotFormData, customerFormData, productsFormData, submit, basePath, navigateTo, selectOrderType} = props;

  if (timeslotFormData.availableOrderDays.length < 1) {
    return <PickupCurrentlyNotAvailableMessage selectOrderType={selectOrderType}/>
  }

  return (
      <React.Fragment>
        <OrderProgressIndicator
            basePath={basePath}
            submitStatus={submitStatus}
            isTimeslotFormDataValid={timeslotFormData.isValid}
            isCustomerFormDataValid={customerFormData.isValid}
            isProductsFormDataValid={productsFormData.isValid}
        />
        <Divider hidden={true}/>
        <Switch>
          <Route path={basePath} exact={true}>
            <OrderTypeAndTimeslotFormPage
                timeslotFormData={timeslotFormData}
                selectOrderType={selectOrderType}
                next={() => navigateTo("products")}
            />
          </Route>
          <ConditionalRoute path={`${basePath}/products`} condition={timeslotFormData.isValid} exact={true} redirectPath={basePath}>
            <ProductsFormPage
                totalAmount={productsFormData.getBasePrice()}
                productsFormData={productsFormData}
                next={() => navigateTo("customer")}
                back={() => navigateTo("")}
            />
          </ConditionalRoute>
          <ConditionalRoute path={`${basePath}/customer`} condition={productsFormData.isValid && timeslotFormData.isValid} redirectPath={basePath}>
            <CustomerFormPage
                customerFormData={customerFormData}
                next={() => navigateTo("summary")}
                back={() => navigateTo("products")}
            />
          </ConditionalRoute>
          <ConditionalRoute
              path={`${basePath}/summary`}
              exact={true}
              condition={customerFormData.isValid && productsFormData.isValid && timeslotFormData.isValid}
              redirectPath={basePath}
          >
            <PickupSummaryPage
                customerFormData={customerFormData}
                timeslotFormData={timeslotFormData}
                productsFormData={productsFormData}
                back={() => navigateTo("customer")}
                submitStatus={submitStatus}
                submit={submit}
            />
          </ConditionalRoute>
        </Switch>
        <Divider hidden={true}/>
      </React.Fragment>
  )

}