import React, {useEffect, useState} from "react";
import {Container, Header, Loader, Segment} from "semantic-ui-react";
import {useAdminContext, useAdminService} from "../context/AdminContext";
import {Shop} from "../types/AdminApiTypes";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

export function SettingsPage() {

  const shopService = useAdminService("shopService");
  const accessQuery = useAdminContext().accessQuery;

  const [initialized, setInitialized] = useState(false)
  const [shop, setShop] = useState<Shop>()


  function toggleIsOrderingEnabled() {
    if (!shop) throw Error()
    const isOrderingEnabled = !shop.isOrderingEnabled
    shopService.updateShop({
      isOrderingEnabled,
    }).then(() => {
      setShop({
        ...shop,
        isOrderingEnabled,
      })
    })
  }

  useEffect(() => {
    shopService.getShop().then(setShop).then(() => setInitialized(true))
  }, [])

  if (!initialized || !shop) {
    return <Loader active={true}/>
  }

  return (
      <Container text={true}>
        <Segment>
          <Header>{shop.name}</Header>
          <Checkbox
              toggle={true} onClick={toggleIsOrderingEnabled} checked={shop.isOrderingEnabled} label={"Bestellungen erlauben"}
              disabled={!accessQuery.canUpdateShop()}
          />
        </Segment>
      </Container>
  );
}



