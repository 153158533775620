import {Checkbox, Divider, Form} from "semantic-ui-react";
import React from "react";
import {CustomerFormData} from "../hooks/UseCustomerFormData";

interface Props {
  formData: CustomerFormData;
  isReadonly: boolean;
}

export function CustomerForm(props: Props) {

  const {formData} = props;
  const {isReadonly} = props;

  const checkboxLabel = (
      <label>
        Ich stimme der Verarbeitung meiner personenbezogenen Daten zu und habe die
        <a target={"_blank"} rel={"noreferrer"} href={"https://www.velani.at/datenschutz"}> Datenschutzbestimmungen </a>
        zur Kenntnis genommen.
      </label>
  )

  return (
      <Form>
        <Form.Group widths={"equal"}>
          <Form.Field required={!isReadonly}>
            <label>Vorname</label>
            <input
                readOnly={isReadonly} placeholder='Vorname' value={formData.firstName ?? ""}
                onChange={event => formData.setFirstName(event.target.value)}
            />
          </Form.Field>
          <Form.Field required={!isReadonly}>
            <label>Nachname</label>
            <input
                readOnly={isReadonly} placeholder='Nachname' value={formData.lastName ?? ""}
                onChange={event => formData.setLastName(event.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field required={!isReadonly}>
          <label>Email-Adresse</label>
          <input
              readOnly={isReadonly} type={"email"} placeholder={"Email-Adresse"} value={formData.emailAddress ?? ""}
              onChange={event => formData.setEmailAddress(event.target.value)}
          />
        </Form.Field>
        <Form.Field required={!isReadonly}>
          <label>Telefonnummer</label>
          <input
              readOnly={isReadonly} placeholder={"Telefonnummer"} value={formData.phoneNumber ?? ""}
              onChange={event => formData.setPhoneNumber(event.target.value)}
          />
        </Form.Field>
        <Divider hidden={true}/>
        <Form.Field>
          <Checkbox
              label={checkboxLabel} readOnly={isReadonly} checked={formData.consented} onChange={() => formData.setConsented(!formData.consented)}
          />
        </Form.Field>
      </Form>
  )

}
