import Axios, {AxiosInstance} from "axios";
import {StompClient} from "./common/stomp/StompClient";
import Keycloak from "keycloak-js";

const KEYCLOAK_URL = process.env.NODE_ENV === "development" ? "http://localhost:8085/auth" : "https://bestellung.velani.at:8445/auth";
const API_BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:8080/api" : "https://www.enerd.eu:8443/velani-order/api";
const STOMP_BASE_URL = process.env.NODE_ENV === "development" ? "ws://localhost:8080/ws" : "wss://www.enerd.eu:8443/velani-order/ws";

export const STOMP_CLIENT = new StompClient(STOMP_BASE_URL);

export const KEYCLOAK = Keycloak({
  url: KEYCLOAK_URL,
  clientId: "velani-order-website",
  realm: "velani-order",
})

export function createAdminInstance(accessToken: string): AxiosInstance {
  const instance: AxiosInstance = Axios.create({
    baseURL: API_BASE_URL
  });
  instance.interceptors.request.use(request => {
    request.headers["Authorization"] = accessToken;
    return request;
  })
  return instance;
}

export function createCustomerInstance(): AxiosInstance {
  return Axios.create({
    baseURL: API_BASE_URL
  });
}

