import {useEffect} from "react";
import {OrderEvent, OrderEvents} from "../../common/stomp/OrderEvents";
import {useAdminContext} from "../context/AdminContext";

export function useOrderEvents(handleEvent: (event: OrderEvent) => any) {

  const connection = useAdminContext().stompConnection;

  function subscribe() {
    const orderEvents = new OrderEvents(connection);
    return orderEvents.subscribe(handleEvent);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(subscribe, [connection]);

}
