import {OrderItemDto} from "../../types/AdminApiTypes";
import {List} from "semantic-ui-react";
import {OrderItem} from "./OrderItem";
import React from "react";

interface Props {
  orderItems: OrderItemDto[];
}

export function OrderItemList(props: Props) {
  return (
      <List bulleted={true}>
        {props.orderItems.map(item => (
            <List.Item key={item.product.id}>
              <OrderItem orderItem={item}/>
            </List.Item>
        ))}
      </List>
  )
}
