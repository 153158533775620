import {Icon, Input} from "semantic-ui-react";
import React from "react";

export type NullableDate = Date | null;

export type DateSetter = (date: Date | null) => void;

export interface Props {
  date: NullableDate;
  setDate: DateSetter;
  min?: NullableDate;
  max?: NullableDate;
  clearable?: boolean;
}

export function DateInput(props: Props) {

  const {date, setDate, min, max, clearable} = props;

  function formatDate(date: Date | null): string {
    if (date === null) return ""
    const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate())
    const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1)
    return `${date.getFullYear()}-${month}-${day}`
  }

  function ClearingItem() {
    return <Icon link={date !== null} name={"x"} onClick={() => setDate(null)} disabled={date === null}/>
  }

  return (
      <Input
          icon={clearable && <ClearingItem/>}
          type={"date"}
          value={formatDate(date)}
          min={formatDate(min ?? null)}
          max={formatDate(max ?? null)}
          onChange={event => setDate(new Date(event.target.value))}
      />
  )

}
