import {useState} from "react";
import {TimeslotFormData} from "./UseTimeslotFormData";
import {DeliveryArea} from "../../../../customer/types/CustomerApiTypes";

export interface Address {
  street: string;
  zipcode: string;
  comment?: string;
}

export type InvalidAddressFormData = {
  street?: string;
  zipcode?: string;
  comment?: string;
  availableZipcodes: string[];
  isValid: false;
  setStreet: (street: string) => void;
  setZipcode: (zipcode: string) => void;
  setComment: (comment: string) => void;
  area?: DeliveryArea;
}

export type ValidAddressFormData = {
  street: string;
  zipcode: string;
  comment?: string;
  availableZipcodes: string[];
  isValid: true;
  setStreet: (street: string) => void;
  setZipcode: (zipcode: string) => void;
  setComment: (comment: string) => void;
  getAddress: () => Address;
  area: DeliveryArea;
}
export type AddressFormData = ValidAddressFormData | InvalidAddressFormData;

type ValidState = {
  street: string;
  area: DeliveryArea;
  zipcode: string;
  comment?: string;
}

type InvalidState = {
  street?: string;
  area?: DeliveryArea;
  zipcode?: string;
  comment?: string;
}

export type Configuration = {
  deliveryAreas: DeliveryArea[];
  timeslotFormData: TimeslotFormData;
  initialState: State;
}

export type State = ValidState | InvalidState;

export function useAddressFormData(config: Configuration): AddressFormData {

  const [state, setState] = useState<State>(config.initialState);

  function selectedTimeslotAllowsZipcode(zipcode: string): boolean {
    return config.timeslotFormData.selectedTimeslot?.allowedZipcodes?.includes(zipcode) ?? true;
  }

  function getAllZipcodes(): string[] {
    function distinct(value: string, index: number, self: string[]) {
      return self.indexOf(value) === index;
    }

    return config.deliveryAreas.flatMap(area => area.zipcodes).filter(distinct).sort()
  }

  function isValidZipcode(zipcode: string): boolean {
    return getAllZipcodes().includes(zipcode)
  }

  function setStreet(street: string) {
    if (street === state.street) return;
    setState(prevState => ({...prevState, street}));
  }

  function setZipcode(zipcode: string) {
    if (zipcode === state.zipcode) return;
    if (!isValidZipcode(zipcode)) throw new Error(`Zipcode ${zipcode} is not valid.`);
    if (!selectedTimeslotAllowsZipcode(zipcode)) {
      config.timeslotFormData.resetSelectedTimeslot();
    }
    const deliveryArea = config.deliveryAreas.find(area => area.zipcodes.includes(zipcode));
    if (!deliveryArea) throw new Error(`Not delivery area with zipcode ${zipcode} available.`)
    setState(prevState => ({...prevState, zipcode, area: deliveryArea}));
  }

  function setComment(comment: string) {
    if (comment === state.comment) return;
    setState(prevState => ({...prevState, comment}));
  }

  function isValid(state: State): state is ValidState {
    return (state.street?.length ?? 0) >= 5 &&
        (state.zipcode?.length ?? 0) >= 4;
  }

  const commonProps = {setStreet, setZipcode, setComment, availableZipcodes: getAllZipcodes()}

  if (isValid(state)) {
    const getAddress: () => Address = () => ({street: state.street, zipcode: state.zipcode, comment: state.comment});
    return {...commonProps, ...state, getAddress, isValid: true};
  } else {
    return {...commonProps, ...state, isValid: false};
  }

}
