import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Form, Icon, List, ListItem, Loader} from "semantic-ui-react";
import {OrderDayDto} from "../types/AdminApiTypes";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {useAdminContext, useAdminService} from "../context/AdminContext";


export function OrderDaysPage() {

  const orderDayService = useAdminService("orderDayService");
  const accessQuery = useAdminContext().accessQuery;

  const [initialized, setInitialized] = useState(false);
  const [orderDays, setOrderDays] = useState<OrderDayDto[]>([])

  function getOrderDays() {
    orderDayService.getAllFromToday()
    .then(setOrderDays)
    .then(() => setInitialized(true));
  }

  useEffect(getOrderDays, [orderDayService]);

  function removeOrderDay(id: number): Promise<any> {
    return orderDayService.remove(id).then(getOrderDays)
  }

  const [date, setDate] = React.useState(new Date().toISOString().substr(0, 10));
  const [timeslots, setTimeslots] = React.useState<string>("");

  function addOrderDay(): Promise<any> {
    const parsedDate = new Date(date);
    const slots = timeslots.trim().length != 0 ? JSON.parse(timeslots) : null;
    return orderDayService.add({date: parsedDate.toISOString(), timeslots: slots}).then(getOrderDays);
  }

  if (!initialized) {
    return <Loader active={true}/>
  }

  return (
      <Container>
        <Form onSubmit={addOrderDay}>
          <Form.Field>
            <label>Datum</label>
            <input type={'date'} placeholder='Datum' value={date} onChange={event => setDate(event.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Timeslots</label>
            <textarea value={timeslots} onChange={event => setTimeslots(event.target.value)}/>
          </Form.Field>
          <Form.Field>
          </Form.Field>
          <Button disabled={!accessQuery.canAddOrderDay()} type='submit'>Hinzufügen</Button>
        </Form>
        <Divider/>
        <Card.Group>
          {orderDays.map(day => <OrderDayCard key={day.id} orderDay={day} remove={removeOrderDay}/>)}
        </Card.Group>
      </Container>
  )

}

interface DateLabelProps {
  orderDay: OrderDayDto;
  remove: (id: number) => Promise<any>;
}

export function OrderDayCard(props: DateLabelProps) {

  const {orderDay} = props;

  return (
      <Card raised={true}>
        <Card.Content>
          <Card.Header>{orderDay.formattedDate}</Card.Header>
          <List size={"large"}>
            {orderDay.slots.map(slot => (
                <ListItem key={slot.id}>
                  {slot.formattedStart} - {slot.formattedEnd}
                  <Icon style={{marginLeft: 10, marginRight: 10}} name={slot.available ? "check" : "x"} color={slot.available ? "green" : "red"}/>
                  {slot.type}
                </ListItem>
            ))}
          </List>
        </Card.Content>
      </Card>
  )

}