import React, {useState} from "react";
import {Button, Checkbox, Form} from "semantic-ui-react";
import {useHistory} from "react-router";


export interface ProductFormData {
  sortIndex: number;
  name: string;
  price: number;
  active: boolean;
  availableUnits: number;
  allergens: string | undefined;
}

interface Props {
  formData: ProductFormData;
  submit: (formData: ProductFormData) => Promise<any>;
}

export function ProductForm(props: Props) {

  const {formData} = props;

  const history = useHistory();

  const [sortIndex, setSortIndex] = useState(formData.sortIndex)
  const [name, setName] = useState(formData.name);
  const [price, setPrice] = useState(String(formData.price));
  const [active, setActive] = useState(formData.active);
  const [availableUnits, setAvailableUnits] = useState(formData.availableUnits);
  const [allergens, setAllergens] = useState(formData.allergens);

  function submit() {
    const parsedPrice = Number.parseFloat(price);
    props.submit({name, price: parsedPrice, active, availableUnits, allergens, sortIndex}).then(history.goBack);
  }

  return (
      <Form onSubmit={submit}>
        <Form.Field>
          <label>Position</label>
          <input placeholder='Position' value={sortIndex} type={"number"} onChange={event => setSortIndex(Number(event.target.value))}/>
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <input placeholder='Name' value={name} onChange={event => setName(event.target.value)}/>
        </Form.Field>
        <Form.Field>
          <label>Preis</label>
          <input placeholder='Preis' value={price} onChange={event => setPrice(event.target.value)}/>
        </Form.Field>
        <Form.Field>
          <Checkbox label='Aktiv' checked={active} onChange={() => setActive(!active)}/>
        </Form.Field>
        <Form.Field>
          <label>Verfügbare Einheiten</label>
          <input
              type={'number'} placeholder='Verfügbare Einheiten' value={availableUnits}
              onChange={event => setAvailableUnits(Number.parseInt(event.target.value))}
          />
        </Form.Field>
        <Form.Field>
          <label>Allergene</label>
          <input placeholder='Allergene' value={allergens} onChange={event => setAllergens(event.target.value)}/>
        </Form.Field>
        <Button type='submit'>Speichern</Button>
      </Form>
  )
}
