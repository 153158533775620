import {Message} from "semantic-ui-react";
import React from "react";

export function FailureView() {
  return (
      <Message error={true}>
        <Message.Header>Fehler</Message.Header>
        <p>
          Die Bestellung konnte nicht durchgeführt werden. Bitte versuchen Sie es etwas später erneut.
        </p>
      </Message>
  )

}
