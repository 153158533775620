import {Container, Menu} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import React from "react";
import {useRoutePaths} from "../context/RoutePathsContext";


interface Props {
  logout: () => void;
}

export function AdminMenu(props: Props) {

  const routePaths = useRoutePaths().employee

  return (
      <Menu stackable={true} widths={5} inverted={false} color={"red"} size={"large"}>
        <Container>
          <Menu.Item as={NavLink} to={routePaths.orders.root()}>
            Bestellungen
          </Menu.Item>
          <Menu.Item as={NavLink} to={routePaths.products.root()}>
            Produkte
          </Menu.Item>
          <Menu.Item as={NavLink} to={routePaths.orderDays.root()}>
            Bestelltage
          </Menu.Item>
          <Menu.Item as={NavLink} to={routePaths.settings.root()}>
            Einstellungen
          </Menu.Item>
          <Menu.Item onClick={props.logout}>
            Logout
          </Menu.Item>
        </Container>
      </Menu>
  )


}
