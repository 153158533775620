import React from "react";
import {Header} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {CustomerForm} from "./CustomerForm";
import {BackButton} from "./BackButton";
import {NextButton} from "./NextButton";
import {CustomerFormData} from "../hooks/UseCustomerFormData";
import {NavigationButtonGrid} from "./NavigationButtonGrid";

interface Props {
  customerFormData: CustomerFormData;
  next: () => any;
  back: () => any;
}

export function CustomerFormPage(props: Props) {

  const {customerFormData, next, back} = props;

  return (
      <React.Fragment>
        <Header as={'h2'} content={'Persönliche Daten'}/>
        <Divider hidden={true}/>
        <CustomerForm isReadonly={false} formData={customerFormData}/>
        <Divider hidden={true}/>
        <NavigationButtonGrid
            backButton={<BackButton onClick={back} disabled={false}/>}
            nextButton={<NextButton onClick={next} disabled={!customerFormData.isValid}/>}
        />
      </React.Fragment>
  )

}
