import {AxiosInstance} from "axios";
import {ProductDto} from "../../admin/types/AdminApiTypes";
import {CustomerService} from "./CustomerService";

export class ProductsCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/products')
  }

  getProducts = (): Promise<ProductDto[]> => {
    return this.httpClient.get('')
  }

}
