import React from "react";

interface Props {
  value: string | undefined | null;
  includeLineBreak?: boolean;
}

export function OptionalComment(props: Props) {
  if (!props.value) return null;
  return (
      <React.Fragment>
        <span>Kommentar: {props.value}</span> {props.includeLineBreak && <br/>}
      </React.Fragment>
  )
}
