import {ProductAdminService} from "../services/ProductAdminService";
import {useEffect, useState} from "react";
import {ProductCreateDto, ProductDto, ProductUpdateDto} from "../types/AdminApiTypes";
import {useFilters} from "./UseFilters";
import {HideNotActiveProductsFilter} from "../utils/ProductFilters";
import {useLocalStorage} from "../../common/hooks/UseLocalStorage";

export function useProductsState(service: ProductAdminService) {

  const [initialized, setInitialized] = useState(false);
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [hideNotActive, setHideNotActive] = useLocalStorage("hideNotActiveProducts", true);

  function getProjects() {
    service.getAll()
    .then(setProducts)
    .then(() => setInitialized(true))
    .catch(() => setInitialized(true));
  }

  function refresh() {
    getProjects()
  }

  function isEmpty() {
    return products.length === 0;
  }

  function find(id: number): ProductDto | undefined {
    return products.find(value => Number(value.id) === Number(id));
  }

  function update(id: number, product: ProductUpdateDto): Promise<any> {
    return service.update(id, product).then(refresh);
  }

  function create(product: ProductCreateDto): Promise<any> {
    return service.create(product).then(refresh);
  }

  function deleteProduct(id: number): Promise<any> {
    return service.deleteProduct(id).then(refresh);
  }

  const filteredProducts = useFilters(products, [
    new HideNotActiveProductsFilter({enabled: hideNotActive}),
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getProjects, []);

  return {
    products: filteredProducts,
    initialized,
    refresh,
    isEmpty,
    find,
    update,
    create,
    deleteProduct,
    filters: {
      isHideNotActiveEnabled: hideNotActive,
      toggleHideNotActive: () => setHideNotActive(!hideNotActive),
    }
  }

}
