import React from "react";
import {Header, Icon} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {OrderProgressIndicator} from "../../common/components/order-form/common/OrderProgressIndicator";
import {SubmitStatus} from "../../common/components/order-form/types/SubmitStatus";

interface Props {
  basePath: string;
}

export function SuccessView(props: Props) {

  return (
      <React.Fragment>
        <OrderProgressIndicator
            basePath={props.basePath}
            submitStatus={SubmitStatus.Success}
            isTimeslotFormDataValid={true}
            isProductsFormDataValid={true}
            isCustomerFormDataValid={true}
        />
        <Header as={"h2"} content={"Bestätigung"}/>
        <Divider hidden={true}/>
        <p>
          Vielen Dank für Ihre Bestellung! Wir haben Ihnen soeben eine Bestellbestätigung per Mail geschickt. <br/> <br/>
          Falls Sie die Bestellung wieder stornieren möchten oder Fragen zu unserem Angebot haben, kontaktieren Sie uns bitte über <a
            href={"mailto:bestellung@velani.at"}
        >bestellung@velani.at</a>.
          <br/> <br/>
          Mit lieben Grüßen, <br/>
          das Velani-Team
        </p>
        <Divider hidden={true}/>
        <div className="ui icon info message">
          <Icon name={"envelope"}/>
          <a href={"https://www.velani.at/menu/abonnieren"} rel={"noreferrer"} target={"_blank"}>
            <div className="content">
              <div className="header">Tipp!</div>
              Über aktuelle Angebote und Neuigkeiten informieren wir Sie gerne mit unserem kostenlosen Newsletter. Für die Anmeldung klicken Sie
              bitte
              hier.
            </div>
          </a>
        </div>
      </React.Fragment>
  )

}

