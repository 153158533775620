import React from "react";
import {Header} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {BackButton} from "./BackButton";
import {NextButton} from "./NextButton";
import {ProductSelectionForm} from "./ProductSelectionForm";
import {ProductsFormData} from "../hooks/UseProductsFormData";
import {NavigationButtonGrid} from "./NavigationButtonGrid";

interface Props {
  productsFormData: ProductsFormData;
  totalAmount: number;
  deliveryFee?: number;
  freeDeliveryThreshold?: number;
  next: () => void;
  back: () => void;
}

export function ProductsFormPage(props: Props) {

  const {productsFormData, next, back} = props;

  return (
      <React.Fragment>
        <Header as={'h2'} content={'Speisen und Getränke auswählen'}/>
        <Divider hidden={true}/>
        <ProductSelectionForm
            isReadonly={false} productsFormData={productsFormData} totalAmount={props.totalAmount} freeDeliveryThreshold={props.freeDeliveryThreshold}
            deliveryFee={props.deliveryFee}
        />
        <Divider hidden={true}/>
        <NavigationButtonGrid
            backButton={<BackButton onClick={back} disabled={false}/>}
            nextButton={<NextButton onClick={next} disabled={!productsFormData.isValid}/>}
        />
      </React.Fragment>
  )

}
