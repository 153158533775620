import {Modal} from "semantic-ui-react";
import React from "react";

export function TopLevelErrorFallback() {
  return (
      <Modal open={true} dimmer={"inverted"} centered={false} size={"tiny"}>
        <Modal.Header>Es ist ein Fehler aufgetreten.</Modal.Header>
        <Modal.Content>
          Unser Entwicklerteam wurde über den Fehler informiert und versucht ihn so schnell wie möglich zu beheben. Bitte versuchen Sie es später
          erneut.
        </Modal.Content>
      </Modal>
  )
}
