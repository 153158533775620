import {AxiosInstance} from "axios";
import {DeliveryDetailsUpdateDto, DeliveryOrderUpdateDto, OrderDto, OrderStatusUpdateDto, PickupOrderUpdateDto} from "../types/AdminApiTypes";
import {AdminService} from "./AdminService";

export class OrderAdminService extends AdminService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/orders');
  }

  getAllFutureOrders = (): Promise<OrderDto[]> => {
    return this.httpClient.get('/future')
  }

  getAllOrders = (): Promise<OrderDto[]> => {
    return this.httpClient.get('')
  }

  updateStatus = (id: number, status: OrderStatusUpdateDto): Promise<void> => {
    return this.httpClient.put(`/${id}/status`, status)
  }

  updateDeliveryDetails = (id: number, deliveryDetails: DeliveryDetailsUpdateDto): Promise<OrderDto> => {
    return this.httpClient.put(`/${id}/delivery-details`, deliveryDetails)
  }

  cancelOrder = (id: number): Promise<void> => {
    return this.httpClient.post(`/${id}/cancel`)
  }

  getOrder = (id: number): Promise<OrderDto> => {
    return this.httpClient.get(`/${id}`)
  }

  updatePickupOrder = (id: number, order: PickupOrderUpdateDto) => {
    return this.httpClient.put(`/${id}/pickup`, order)
  }

  updateDeliveryOrder = (id: number, order: DeliveryOrderUpdateDto) => {
    return this.httpClient.put(`/${id}/delivery`, order)
  }

}
