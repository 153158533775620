import React from "react";
import {Button, Container, Loader, Popup, Table} from "semantic-ui-react";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import {ProductDto, ProductUpdateDto} from "../types/AdminApiTypes";
import {Route, useHistory} from "react-router";
import {NewProductModal} from "../components/products/NewProductModal";
import {UpdateProductModal} from "../components/products/UpdateProductModal";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {useAdminContext, useAdminService} from "../context/AdminContext";
import {useProductsState} from "../hooks/UseProductsState";
import {useRoutePaths} from "../context/RoutePathsContext";

export function ProductsPage() {

  const productService = useAdminService("productService");
  const accessQuery = useAdminContext().accessQuery;

  const productsState = useProductsState(productService);

  const history = useHistory();
  const routePaths = useRoutePaths().employee.products

  if (!productsState.initialized) {
    return <Loader active={true}/>
  }

  return (
      <React.Fragment>
        <Container>
          <Button primary={true} onClick={() => history.push(routePaths.new())} disabled={!accessQuery.canCreateProduct()}>Hinzufügen</Button>
          <Checkbox
              checked={productsState.filters.isHideNotActiveEnabled}
              onClick={productsState.filters.toggleHideNotActive}
              label={"Nur aktive Produkte anzeigen"}
          />
          {!productsState.isEmpty() && (
              <Table celled={true}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign={"center"}>Pos.</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>Allergene</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>Preis</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>Aktiv</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>Verfügbar</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"center"}>Aktionen</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {productsState.products.map(product => <ProductRow
                      key={product.id} product={product} update={productsState.update} deleteProduct={productsState.deleteProduct}
                  />)}
                </Table.Body>
              </Table>
          )}
        </Container>
        <Route path={routePaths.new()} render={() => <NewProductModal create={productsState.create}/>}/>
        <Route
            path={routePaths.product.root()} render={route => {
          const id = route.match.params?.id;
          const product = productsState.find(id);
          if (productsState.isEmpty() || product === undefined) return null;
          return <UpdateProductModal product={product} update={productsState.update}/>
        }}
        />
        <Divider hidden={true}/>
      </React.Fragment>
  );
}

interface ProductRowProps {
  product: ProductDto;
  update: (id: number, product: ProductUpdateDto) => any;
  deleteProduct: (id: number) => Promise<any>;
}

function ProductRow(props: ProductRowProps) {

  const {product} = props;

  function updateActive(active: boolean) {
    const {id, ...remaining} = product;
    const updated = {...remaining, active};
    props.update(id, updated);
  }

  const history = useHistory();
  const accessQuery = useAdminContext().accessQuery;
  const routePaths = useRoutePaths().employee.products

  return (
      <Table.Row>
        <Table.Cell textAlign={"center"}>{product.sortIndex}</Table.Cell>
        <Table.Cell>{product.name}</Table.Cell>
        <Table.Cell textAlign={"center"}>{product.allergens}</Table.Cell>
        <Table.Cell textAlign={"center"}>{product.formattedPrice} €</Table.Cell>
        <Table.Cell textAlign={"center"}>
          <Checkbox disabled={!accessQuery.canUpdateProduct()} checked={product.active} onClick={() => updateActive(!product.active)}/>
        </Table.Cell>
        <Table.Cell textAlign={"center"}>
          {product.availableUnits}
        </Table.Cell>
        <Table.Cell textAlign={"center"}>
          <Button.Group size={"large"}>
            <Popup on={"click"} trigger={<Button icon={"trash"} disabled={!accessQuery.canDeleteProduct()}/>}>
              <Button color={"red"} onClick={() => props.deleteProduct(product.id)}>Unwiderruflich löschen</Button>
            </Popup>
            <Button
                disabled={!accessQuery.canUpdateProduct()} onClick={() => history.push(routePaths.product.root(String(product.id)))} icon={"edit"}
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
  )

}

