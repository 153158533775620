import React from "react";
import {ProductSelectionTable} from "./ProductSelectionTable";
import {Divider, Dropdown, DropdownItemProps, Form, FormField, Grid, Header, TextArea} from "semantic-ui-react";
import {ProductsFormData} from "../hooks/UseProductsFormData";

interface Props {
  productsFormData: ProductsFormData;
  totalAmount: number;
  deliveryFee?: number;
  freeDeliveryThreshold?: number;
  isReadonly: boolean;
}

export function ProductSelectionForm(props: Props) {

  const {productsFormData, isReadonly} = props;

  function getPackagingOptions(): DropdownItemProps[] {
    return productsFormData.packagingOptions.map(option => ({
      key: option.id,
      text: option.name,
      value: option.id,
      description: formatPrice(option.price),
    }))
  }

  return (
      <React.Fragment>
        <ProductSelectionTable isReadonly={isReadonly} productAmounts={productsFormData.productAmounts}/>
        <Form>
          <FormField>
            <label>Verpackung</label>
            <Dropdown
                disabled={isReadonly}
                fluid={true}
                selection={true}
                options={getPackagingOptions()}
                value={productsFormData.selectedPackagingOption.id}
                onChange={(event, data) => productsFormData.setSelectedPackagingOption(Number(data.value))}
            />
          </FormField>
          <FormField>
            <label>Bemerkungen</label>
            <TextArea
                readOnly={isReadonly}
                placeholder={'Sonderwünsche (z.B. andere Beilage, Allergene, ...)'}
                rows={4}
                value={productsFormData.productsComment ?? ""}
                onChange={event => productsFormData.setProductsComment(event.target.value)}
            />
          </FormField>
        </Form>
        <Divider hidden={true}/>
        <Grid divided={"vertically"} padded={false}>
          <Grid.Row columns={2}>
            <Grid.Column width={10}>
              Produktkosten:
            </Grid.Column>
            <Grid.Column textAlign={"right"} width={6}>
              {formatPrice(productsFormData.getProductsPrice())}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={10}>
              Verpackungskosten:
              <br/>
              <small>
                {productsFormData.getPackagingUsed()}x {productsFormData.selectedPackagingOption.name} à {formatPrice(productsFormData.selectedPackagingOption.price)}
              </small>
            </Grid.Column>
            <Grid.Column textAlign={"right"} width={6}>
              {formatPrice(productsFormData.getPackagingPrice())}
            </Grid.Column>
          </Grid.Row>
          {props.deliveryFee !== undefined && (
              <Grid.Row columns={2}>
                <Grid.Column width={10}>
                  Lieferkosten:
                  <br/> <small>Kostenlose Lieferung ab einem Bestellwert von {formatPrice(props.freeDeliveryThreshold!!)}</small>
                </Grid.Column>
                <Grid.Column textAlign={"right"} width={6}>
                  {formatPrice(props.deliveryFee)}
                </Grid.Column>
              </Grid.Row>
          )}
          <Grid.Row columns={2}>
            <Grid.Column textAlign={"left"}>
              <Header as={'h3'}>Gesamt:</Header>
            </Grid.Column>
            <Grid.Column textAlign={"right"}>
              <Header as={"h3"}>
                {formatPrice(props.totalAmount)}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
  )
}

export function formatPrice(price: number): string {
  return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price);
}