import {OrderViewProps} from "./OrderViewProps";
import React, {useMemo} from "react";
import {Header} from "semantic-ui-react";
import {OrderTable} from "../OrderTable";
import {OrderViewMenu} from "../../OrderViewMenu";

export function ServiceOrderView(props: OrderViewProps) {

  const deliveryOrders = useMemo(() => {
    return props.ordersState.orders.filter(order => order.type === "DELIVERY");
  }, [props.ordersState.orders]);


  const pickupOrders = useMemo(() => {
    return props.ordersState.orders.filter(order => order.type === "PICKUP");
  }, [props.ordersState.orders]);

  return (
      <React.Fragment>
        <OrderViewMenu {...props}/>
        {pickupOrders.length > 0 && (
            <React.Fragment>
              <Header>Pickup</Header>
              <OrderTable {...props} name={"pickupOrderTable"} pageSize={3} ordersState={{...props.ordersState, orders: pickupOrders}}
                          isDelivery={false}/>
            </React.Fragment>
        )}
        {deliveryOrders.length > 0 && (
            <React.Fragment>
              <Header>Delivery</Header>
              <OrderTable {...props} name={"deliveryOrderTable"} pageSize={3} ordersState={{...props.ordersState, orders: deliveryOrders}}
                          isDelivery={true}/>
            </React.Fragment>
        )}
      </React.Fragment>
  )
}
