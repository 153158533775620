import {Document, Page, pdf, Text, View} from "@react-pdf/renderer";
import React from "react";
import {groupOrdersByProduct, groupOrdersItemsByOrderReadyTime, OrderItemProductGroup, ReadyTimeOrderGroup} from "./OrderGrouping";
import {EnhancedOrder} from "../hooks/UseOrdersState";
import {saveAs} from "file-saver";

interface Props {
  title: string;
  timeslotGroups: ReadyTimeOrderGroup[],
  orderItemProductGroups: OrderItemProductGroup[],
}

function KitchenTimeslotGroupsDocument(props: Props) {

  const {timeslotGroups, orderItemProductGroups, title} = props;

  if (timeslotGroups.length === 0) {
    throw new Error("Can not create a document with no timeslotGroups.");
  }

  function getAmountsText(product: OrderItemProductGroup): string {
    let text = `${product.accumulatedAmount}x ${product.product.name}:     `
    for (let packagingAmount of product.packagingAmounts) {
      text += `${packagingAmount.amount}x ${packagingAmount.packagingOption.abbreviation}, `
    }
    return text
  }

  return (
      <Document title={title} subject={title}>
        <Page
            size="A4" style={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingHorizontal: 25,
        }}
        >
          <View style={{fontSize: 15}}>
            <Text>{title}</Text>
          </View>
          <View>
            <Text style={{fontSize: 12, marginTop: 6}}>Gesamtübersicht</Text>
          </View>
          <View
              children={orderItemProductGroups.map(group => (
                  <Text style={{fontSize: 10}}>{group.accumulatedAmount}x {group.product.name}</Text>
              ))}
          />
          {timeslotGroups.map(group => (
              <View wrap={false} style={{marginTop: 6}}>
                <Text style={{fontSize: 12}}>{group.formattedReadyTime} - {group.orders.length} {group.commonOrderType}</Text>
                <View
                    children={group.orderItemGroups.map(product => (<Text style={{fontSize: 10}}>{getAmountsText(product)}</Text>))}
                />
                {group.productComments.length > 0 && <Text style={{fontSize: 10}}>Kommentare: {"\n"} {group.productComments.join("\n")}</Text>}
              </View>
          ))}
        </Page>
      </Document>
  )
}

export function downloadKitchenOverviewDocument(orders: EnhancedOrder[]) {
  const timeslotGroups = groupOrdersItemsByOrderReadyTime(orders);
  const orderItemProductGroups = groupOrdersByProduct(orders);
  const title = `Küchenübersicht ${timeslotGroups[0]?.formattedReadyTime} - ${timeslotGroups[timeslotGroups.length - 1]?.formattedReadyTime}`
  const document = <KitchenTimeslotGroupsDocument title={title} timeslotGroups={timeslotGroups} orderItemProductGroups={orderItemProductGroups}/>
  pdf(document).toBlob().then(blob => {
    saveAs(blob, title);
  })
}
