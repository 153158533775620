import {Redirect, Route, Switch} from "react-router-dom";
import {AdminApp} from "../../admin/AdminApp";
import React from "react";
import {CustomerApp} from "../../customer/CustomerApp";

interface Props {
  shop: string;
  basePath: string;
}

export function AppRoutes(props: Props) {

  const {shop, basePath} = props;

  function getCustomerAppPath(): string {
    return basePath
  }

  function getAdminAppPath(): string {
    return `${basePath}/admin`
  }

  return (
      <Switch>
        <Route path={getAdminAppPath()} exact={false}>
          <AdminApp shop={shop}/>
        </Route>
        <Route path={getCustomerAppPath()} exact={false}>
          <CustomerApp shop={shop} basePath={getCustomerAppPath()}/>
        </Route>
        <Redirect to={getCustomerAppPath()}/>
      </Switch>
  )

}
