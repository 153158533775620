import {PackagingOption, Product} from "../../../../customer/types/CustomerApiTypes";
import {useState} from "react";

export interface OrderItem {
  productId: number;
  amount: number;
}

export type ProductsFormData = {
  productAmounts: ModifiableProductAmount[];
  packagingOptions: PackagingOption[];
  productsComment: string | undefined;
  getBasePrice: () => number;
  getPackagingPrice: () => number;
  getPackagingUsed: () => number;
  getProductsPrice: () => number;
  orderItems: OrderItem[];
  setProductsComment: (productsComment: string) => void;
  setSelectedPackagingOption: (id: number) => void;
  selectedPackagingOption: PackagingOption;
  isValid: boolean;
}

export type ProductAmount = {
  product: Product;
  amount: number;
}

export type ModifiableProductAmount = ProductAmount & {
  setAmount: (amount: number) => void;
}

export type State = {
  productAmounts: ProductAmount[];
  selectedPackagingOption: PackagingOption;
  productsComment?: string;
};

export type Configuration = {
  initialState: State;
  packagingOptions: PackagingOption[]
}

export function useProductsFormData(config: Configuration): ProductsFormData {

  const [productAmounts, setProductAmounts] = useState(config.initialState.productAmounts);
  const [productsComment, setProductsComment] = useState(config.initialState.productsComment);
  const [selectedPackagingOption, setSelectedPackagingOption] = useState(config.initialState.selectedPackagingOption)

  function setAmount(productId: number, amount: number) {
    if (amount < 0) throw new Error(`Amount can not be smaller than 0 but was ${amount}.`);
    const index = productAmounts.findIndex(element => Number(element.product.id) === Number(productId));
    const productAmount = productAmounts[index];
    if (productAmount === undefined) throw new Error(`Could not find ProductAmount for product with id=${productId}.`);
    if (Number(productAmount.amount) === Number(amount)) return;
    const updatedState = [...productAmounts];
    updatedState[index] = {...productAmount, amount};
    setProductAmounts(updatedState);
  }

  function setSelectedPackagingOptionById(id: number) {
    const packagingOption = config.packagingOptions.find(option => Number(option.id) === Number(id))
    if (!packagingOption) throw  Error(`Packaging option with id '${id}' could not be found.`)
    setSelectedPackagingOption(packagingOption)
  }

  function getModifiableProductAmounts(): ModifiableProductAmount[] {
    return productAmounts.map(productAmount => ({
      ...productAmount,
      setAmount: amount => setAmount(productAmount.product.id, amount),
    }));
  }

  function getOrderItems(): OrderItem[] {
    return productAmounts
    .filter(productAmount => productAmount.amount > 0)
    .map(productAmount => ({productId: productAmount.product.id, amount: productAmount.amount}));
  }

  function getProductsPrice(): number {
    return productAmounts.reduce((sum, productAmount) => sum + productAmount.amount * productAmount.product.price, 0);
  }

  function getBasePrice(): number {
    return getProductsPrice() + getPackagingPrice();
  }

  function getPackagingUsed(): number {
    return productAmounts.reduce((sum, item) => sum + item.amount, 0)
  }

  function getPackagingPrice(): number {
    return getPackagingUsed() * selectedPackagingOption.price;
  }

  return {
    productAmounts: getModifiableProductAmounts(),
    orderItems: getOrderItems(),
    isValid: getOrderItems().length > 0,
    setProductsComment,
    productsComment,
    getBasePrice,
    packagingOptions: config.packagingOptions,
    setSelectedPackagingOption: setSelectedPackagingOptionById,
    selectedPackagingOption,
    getPackagingUsed,
    getPackagingPrice,
    getProductsPrice,
  }

}