import {Redirect, Route, RouteProps} from "react-router-dom";
import React from "react";

interface Props {
  condition: boolean;
  redirectPath: string;
}

export function ConditionalRoute(props: RouteProps & Props) {

  const {condition, redirectPath, ...routeProps} = props;

  if (!props.condition) return <Redirect to={redirectPath}/>;

  return <Route {...routeProps}/>

}
