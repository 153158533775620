import React, {useEffect, useState} from "react";
import {EnhancedOrder} from "../hooks/UseOrdersState";
import {SubmitStatus} from "../../common/components/order-form/types/SubmitStatus";
import {Button, Container, Divider, Header, Loader, Popup} from "semantic-ui-react";
import {DeliveryOrderFormData, OrderForm, PickupOrderFormData} from "../../common/components/order-form/OrderForm";
import {useHistory} from "react-router";
import {OrderCard} from "../components/orders/OrderCard";
import {toast} from "react-toastify";
import {DeliveryArea, OrderDay, PackagingOption, Product} from "../../customer/types/CustomerApiTypes";
import {useAdminService} from "../context/AdminContext";
import {useRoutePaths} from "../context/RoutePathsContext";

interface Props {
  order: EnhancedOrder;
  submitCallback: () => any;
}

export function OrderEditPage(props: Props) {

  const {order} = props;

  const history = useHistory();
  const routePaths = useRoutePaths().employee.orders

  const orderDayService = useAdminService("orderDayService");
  const productService = useAdminService("productService");
  const orderService = useAdminService("orderService");
  const orderDayCustomerService = useAdminService("orderDayCustomerService")
  const deliveryAddressCustomerService = useAdminService("deliveryAddressService")
  const packagingOptionsCustomerService = useAdminService("packagingOptionsCustomerService")

  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.NotSubmitted);

  const [initialized, setInitialized] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryArea[]>([]);
  const [orderDays, setOrderDays] = useState<OrderDay[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [packagingOptions, setPackagingOptions] = useState<PackagingOption[]>([]);

  function withOriginalOrderDay(orderDays: OrderDay[], originalOrderDay: OrderDay): OrderDay[] {
    const originalTimeslot = originalOrderDay.slots.find(slot => Number(slot.id) === Number(order.timeslot.timeslotId))!!;
    const modifiedTimeslot = {...originalTimeslot, available: true};
    const modifiedTimeslots = [modifiedTimeslot, ...originalOrderDay.slots.filter(slot => Number(slot.id) !== Number(originalTimeslot.id))];
    const modifiedOrderDay = {...originalOrderDay, slots: modifiedTimeslots};
    return [modifiedOrderDay, ...orderDays.filter(day => Number(day.id) !== Number(originalOrderDay.id))];
  }

  function initialize() {
    Promise.all([
      orderDayService.getById(order.timeslot.orderDayId),
      orderDayCustomerService.getAvailableOrderDays(),
      productService.getAll(),
      deliveryAddressCustomerService.getDeliveryAreas().then(setDeliveryAreas),
      packagingOptionsCustomerService.getPackagingOptions().then(setPackagingOptions),
    ]).then(results => {
      const originalOrderDay = results[0];
      const orderDays = results[1];
      setOrderDays(withOriginalOrderDay(orderDays, originalOrderDay))
      if (order.type === "DELIVERY") {
        history.push(routePaths.order.edit.delivery(String(order.id)));
      }
      const allProducts = results[2];
      setAvailableProducts(modifyProducts(allProducts));
      setInitialized(true);
    }).catch(() => setInitialized(true));
  }

  function modifyProducts(products: Product[]): Product[] {
    return products.map(product => {
      const orderItem = order.orderItems.find(item => Number(item.product.id) === Number(product.id));
      const additionalAmount = orderItem ? orderItem.amount : 0;
      return {
        ...product,
        active: product.active || orderItem !== undefined,
        availableUnits: product.availableUnits + additionalAmount
      };
    }).filter(product => product.availableUnits > 0 && product.active);
  }

  function updatePickupOrder(formData: PickupOrderFormData) {
    setSubmitStatus(SubmitStatus.InProgress);
    orderService.updatePickupOrder(order.id, {
      orderDayId: formData.timeslotFormData.selectedOrderDay.id,
      timeslotId: formData.timeslotFormData.selectedTimeslot.id,
      orderItems: formData.productsFormData.orderItems,
      customer: formData.customerFormData.getCustomer(),
      productsComment: formData.productsFormData.productsComment,
      packagingOptionId: formData.productsFormData.selectedPackagingOption.id,
    }).then(() => {
      toast("Bestellung gespeichert.", {type: "success"});
      setSubmitStatus(SubmitStatus.NotSubmitted);
      props.submitCallback();
    });
  }

  function updateDeliveryOrder(formData: DeliveryOrderFormData) {
    setSubmitStatus(SubmitStatus.InProgress);
    orderService.updateDeliveryOrder(order.id, {
      orderDayId: formData.timeslotFormData.selectedOrderDay.id,
      timeslotId: formData.timeslotFormData.selectedTimeslot.id,
      orderItems: formData.productsFormData.orderItems,
      customer: formData.customerFormData.getCustomer(),
      productsComment: formData.productsFormData.productsComment,
      deliveryAddress: formData.deliveryAddressFormData.getAddress(),
      packagingOptionId: formData.productsFormData.selectedPackagingOption.id,
    }).then(() => {
      toast("Bestellung gespeichert.", {type: "success"});
      setSubmitStatus(SubmitStatus.NotSubmitted);
      props.submitCallback();
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);

  if (!initialized) {
    return <Loader active={true}/>
  }

  return (
      <Container text={true}>
        <Header>Bestellung editieren - {order.id}
          <Popup on={"click"} trigger={<Button basic={true} floated={"right"}>Originale Bestellung</Button>}>
            <OrderCard order={order} readonly={true}/>
          </Popup>
        </Header>
        <Divider hidden={true}/>
        <OrderForm
            basePath={routePaths.order.edit.root(String(order.id))}
            submitStatus={submitStatus}
            orderDays={orderDays}
            products={availableProducts}
            deliveryAreas={deliveryAreas}
            submitPickup={updatePickupOrder}
            submitDelivery={updateDeliveryOrder}
            packagingOptions={packagingOptions}
            order={order}
        />
      </Container>
  )
}
