import {useState} from "react";
import {isValidEmail} from "../../../utils/Validation";

export type Customer = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export type ValidCustomerFormData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  consented: true;
  isValid: true;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmailAddress: (emailAddress: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setConsented: (consented: boolean) => void;
  getCustomer: () => Customer;
}
export type InvalidCustomerFormData = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  consented: boolean;
  isValid: false;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmailAddress: (emailAddress: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setConsented: (consented: boolean) => void;
}
export type CustomerFormData = ValidCustomerFormData | InvalidCustomerFormData;

export type ValidState = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  consented: true;
}

export type InvalidState = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  consented: boolean;
}

export type State = ValidState | InvalidState;

export type Configuration = {
  initialState: State;
}

export function useCustomerFormData(config: Configuration): CustomerFormData {

  const [state, setState] = useState<State>(config.initialState);

  function setFirstName(firstName: string) {
    if (state.firstName === firstName) return;
    setState(prevState => ({...prevState, firstName}));
  }

  function setLastName(lastName: string) {
    if (state.lastName === lastName) return;
    setState(prevState => ({...prevState, lastName}));
  }

  function setEmailAddress(emailAddress: string) {
    if (state.emailAddress === emailAddress) return;
    setState(prevState => ({...prevState, emailAddress}));
  }

  function setPhoneNumber(phoneNumber: string) {
    if (state.phoneNumber === phoneNumber) return;
    setState(prevState => ({...prevState, phoneNumber}));
  }

  function setConsented(consented: boolean) {
    if (state.consented === consented) return;
    setState(prevState => ({...prevState, consented}));
  }

  function isValid(state: State): state is ValidState {
    return state.consented &&
        (state.firstName?.length ?? 0) > 2 &&
        (state.lastName?.length ?? 0) > 2 &&
        isValidEmail(state.emailAddress ?? "") &&
        (state.phoneNumber?.length ?? 0) > 5;
  }

  const commonProps = {setFirstName, setLastName, setEmailAddress, setPhoneNumber, setConsented};

  if (isValid(state)) {
    const getCustomer: () => Customer = () => ({
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.emailAddress,
      phoneNumber: state.phoneNumber,
    })
    return {...commonProps, ...state, getCustomer, isValid: true};
  } else {
    return {...commonProps, ...state, isValid: false};
  }

}
