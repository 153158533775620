import React from 'react';
import {Route} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import {createIntegratedRoutePaths, createStandaloneRoutePaths, RoutePathsContextProvider} from "./admin/context/RoutePathsContext";
import {AppRoutes} from "./common/components/AppRoutes";


export function IntegratedApp() {

  return (
      <Route path={'/:shop'}>
        {data => {
          const shop: string | undefined = data.match?.params.shop
          if (!shop) throw new Error()

          const routePaths = createIntegratedRoutePaths(shop)

          return (
              <RoutePathsContextProvider routePaths={routePaths}>
                <AppRoutes basePath={`/${shop}`} shop={shop}/>
              </RoutePathsContextProvider>
          )
        }}
      </Route>
  );
}

interface Props {
  shop: string;
}

export function StandaloneApp(props: Props) {

  const routePaths = createStandaloneRoutePaths()

  return (
      <RoutePathsContextProvider routePaths={routePaths}>
        <AppRoutes basePath={""} shop={props.shop}/>
      </RoutePathsContextProvider>
  )

}
