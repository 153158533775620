import React from "react";
import {PackagingOptionData} from "../../types/AdminApiTypes";
import {Label} from "semantic-ui-react";
import {formatPrice} from "../../../common/components/order-form/common/ProductSelectionForm";


interface Props {
  packagingOption: PackagingOptionData;
  amount: number;
}

export function PackagingOptionLabel(props: Props) {

  function calculateTotal() {
    return props.amount * props.packagingOption.price;
  }

  return <Label>{props.amount}x {props.packagingOption.name} - {formatPrice(calculateTotal())}</Label>

}