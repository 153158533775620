import {Button, Modal} from "semantic-ui-react";
import React from "react";
import {ProductCreateDto} from "../../types/AdminApiTypes";
import {useHistory} from "react-router";
import {ProductForm, ProductFormData} from "./ProductForm";


interface Props {
  create: (product: ProductCreateDto) => Promise<any>;
}

export function NewProductModal(props: Props) {

  const history = useHistory();

  const initialFormData: ProductFormData = {
    sortIndex: 0, name: '', price: 0, active: true, availableUnits: 0, allergens: undefined
  }


  function submit(formData: ProductFormData) {
    const {availableUnits, ...remaining} = formData;
    return props.create({...remaining, initialInventory: availableUnits});
  }

  return (
      <Modal open={true} onClose={history.goBack}>
        <Modal.Header>Produkt hinzufügen</Modal.Header>
        <Modal.Content>
          <ProductForm formData={initialFormData} submit={submit}/>
        </Modal.Content>
        <Modal.Actions>
          <Button
              content="Abbrechen"
              onClick={history.goBack}
              negative={true}
          />
        </Modal.Actions>
      </Modal>
  )
}
