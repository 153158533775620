import {Dropdown, DropdownItemProps, Form, FormField} from "semantic-ui-react";
import React from "react";
import {OrderDay, Timeslot} from "../../../../customer/types/CustomerApiTypes";

interface TimeslotFormProps {
  orderDays: OrderDay[];
  setSelectedOrderDay: (day: number) => any;
  selectedOrderDay?: OrderDay;
  selectedSlot?: Timeslot;
  setSelectedSlot: (slot: number) => any;
  orderDayLabel: string;
  timeslotLabel: string;
  isReadonly?: boolean;
}

export function TimeslotForm(props: TimeslotFormProps) {

  const {orderDays} = props;

  function hasNoAvailableSlots(orderDay: OrderDay): boolean {
    return !orderDay.slots.some(slot => slot.available)
  }

  const daysOptions: DropdownItemProps[] = orderDays.map(orderDay => ({
    key: orderDay.id,
    text: orderDay.formattedDate,
    value: orderDay.id,
    disabled: hasNoAvailableSlots(orderDay),
  }));

  return (
      <Form>
        <FormField required={true}>
          <label>{props.orderDayLabel}</label>
          <Dropdown
              disabled={props.isReadonly} options={daysOptions} fluid={true} selection={true} placeholder={props.orderDayLabel}
              value={props.selectedOrderDay?.id ?? props.orderDayLabel}
              onChange={(_, data) => props.setSelectedOrderDay(Number(data.value))}
          />
        </FormField>
        {props.selectedOrderDay && (
            <FormField required={true}>
              <label>{props.timeslotLabel}</label>
              <TimeslotDropdown
                  isReadonly={props.isReadonly} timeslots={props.selectedOrderDay.slots}
                  selectedSlot={props.selectedSlot}
                  setSelectSlot={props.setSelectedSlot} label={props.timeslotLabel}
              />
            </FormField>
        )}
      </Form>
  )
}


interface TimeslotDropdownProps {
  timeslots: Timeslot[];
  selectedSlot?: Timeslot;
  setSelectSlot: (slotId: number) => any;
  label: string;
  isReadonly?: boolean;
}

function TimeslotDropdown(props: TimeslotDropdownProps) {
  const slotOptions: DropdownItemProps[] = props.timeslots.map(slot => ({
    key: slot.id,
    text: `${slot.formattedStart} - ${slot.formattedEnd}`,
    value: slot.id,
    disabled: !slot.available
  }));

  return (
      <Dropdown
          disabled={props.isReadonly} options={slotOptions} fluid={true} selection={true} placeholder={props.label}
          value={props.selectedSlot?.id ?? props.label}
          onChange={(_, data) => props.setSelectSlot(Number(data.value))}
      />
  )
}
