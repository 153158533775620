import React from "react";
import {Divider, Header} from "semantic-ui-react";
import {TimeslotForm} from "../common/TimeslotForm";
import {NextButton} from "../common/NextButton";
import {OrderTypeSelector} from "../common/OrderTypeSelector";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";
import {OrderType} from "../../../../admin/pages/OrdersPage";
import {NavigationButtonGrid} from "../common/NavigationButtonGrid";

export interface Props {
  timeslotFormData: TimeslotFormData;
  selectOrderType: (orderType: OrderType) => any;
  next: () => any;
}

export function OrderTypeAndTimeslotFormPage(props: Props) {

  const {timeslotFormData, next, selectOrderType} = props;

  return (
      <React.Fragment>
        <OrderTypeSelector orderType={"PICKUP"} selectOrderType={selectOrderType}/>
        <Divider hidden={true}/>
        <Header as='h2'>Abholtermin</Header>
        <TimeslotForm
            orderDays={timeslotFormData.availableOrderDays}
            setSelectedOrderDay={timeslotFormData.setSelectedOrderDay}
            selectedOrderDay={timeslotFormData.selectedOrderDay}
            selectedSlot={timeslotFormData.selectedTimeslot}
            setSelectedSlot={timeslotFormData.setSelectedTimeslot}
            timeslotLabel={"Abholzeit"}
            orderDayLabel={"Abholtag"}
        />
        <Divider hidden={true}/>
        <NavigationButtonGrid
            nextButton={<NextButton onClick={next} disabled={!timeslotFormData.isValid}/>}
        />
      </React.Fragment>
  )

}
