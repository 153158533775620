import React, {ReactNode, useContext} from "react";
import {StompConnection} from "../../common/stomp/StompClient";
import {OrderAdminService} from "../services/OrderAdminService";
import {DriverAdminService} from "../services/DriverAdminService";
import {OrderDayAdminService} from "../services/OrderDayService";
import {ProductAdminService} from "../services/ProductAdminService";
import {AccessQuery} from "../services/AccessQuery";
import {OrderDayCustomerService} from "../../customer/services/OrderDayCustomerService";
import {DeliveryCustomerService} from "../../customer/services/DeliveryCustomerService";
import {PackagingOptionsCustomerService} from "../../customer/services/PackagingOptionsCustomerService";
import {ShopEmployeeService} from "../services/ShopEmployeeService";

export interface AdminContext {
  stompConnection: StompConnection,
  services: AdminServices,
  accessQuery: AccessQuery,
}

interface AdminServices {
  orderService: OrderAdminService,
  driverService: DriverAdminService,
  orderDayService: OrderDayAdminService,
  productService: ProductAdminService,
  orderDayCustomerService: OrderDayCustomerService,
  deliveryAddressService: DeliveryCustomerService,
  packagingOptionsCustomerService: PackagingOptionsCustomerService,
  shopService: ShopEmployeeService,
}

// @ts-ignore
const ReactAdminContext = React.createContext<AdminContext>(null)

interface Props {
  context: AdminContext;
  children: ReactNode;
}

export function useAdminContext(): AdminContext {

  return useContext(ReactAdminContext);

}

export function useAdminService<K extends keyof AdminServices>(name: K): AdminServices[K] {

  const context = useAdminContext()

  return context.services[name];

}

export function AdminContextProvider(props: Props) {

  return (
      <ReactAdminContext.Provider value={props.context} children={props.children}/>
  )

}
