import React from "react";
import {OrderViewProps} from "./OrderViewProps";
import {Container, Icon, Label, Table} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {groupOrdersByProduct, groupOrdersItemsByOrderReadyTime, ReadyTimeOrderGroup} from "../../../utils/OrderGrouping";
import {OrderViewMenu} from "../../OrderViewMenu";

export function KitchenOrderView(props: OrderViewProps) {

  const {ordersState} = props;

  const readyTimeOrderGroups = groupOrdersItemsByOrderReadyTime(ordersState.orders);
  const orderItemProductGroups = groupOrdersByProduct(ordersState.orders);

  return (
      <React.Fragment>
        <OrderViewMenu {...props}/>
        <Container text={true}>
          {orderItemProductGroups.length > 0 && (
              <Table striped={false} collapsing={false} compact={true} basic={false} color={"blue"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>
                      <Icon name={"calendar"} color={"blue"}/>
                      Gesamtübersicht für <span/>
                      {readyTimeOrderGroups[0].formattedReadyTime} - {readyTimeOrderGroups[readyTimeOrderGroups.length - 1].formattedReadyTime}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {orderItemProductGroups.map(productAmount => (
                      <Table.Row key={productAmount.product.name}>
                        <Table.Cell>
                          {productAmount.product.name}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          <Label color={"blue"}>{productAmount.accumulatedAmount}x Gesamt</Label>
                        </Table.Cell>
                      </Table.Row>
                  ))}
                </Table.Body>
              </Table>
          )}
          <Divider hidden={true}/>
          {readyTimeOrderGroups.map(group => (
              <React.Fragment key={group.formattedReadyTime}>
                <ReadyTimeOrderGroupTable group={group}/>
                <Divider hidden={true}/>
              </React.Fragment>
          ))}
        </Container>
      </React.Fragment>
  )

}

function ReadyTimeOrderGroupTable(props: { group: ReadyTimeOrderGroup }) {
  const {group} = props;
  return (
      <Table striped={false} collapsing={false} compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='2'>{group.formattedReadyTime} - {group.orders.length} {group.commonOrderType}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {group.orderItemGroups.map((productAmount, index) => (
              <Table.Row key={productAmount.product.name}>
                <Table.Cell>
                  {productAmount.product.name}
                </Table.Cell>
                <Table.Cell textAlign={"right"}>
                  {productAmount.packagingAmounts.map(packagingAmount => (
                      <Label key={packagingAmount.packagingOption.id}>{packagingAmount.amount}x {packagingAmount.packagingOption.abbreviation}</Label>
                  ))}
                  <Label color={"blue"}>{productAmount.accumulatedAmount}x Gesamt</Label>
                </Table.Cell>
              </Table.Row>
          ))}
        </Table.Body>
        {group.productComments.length > 0 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={2}>
                  <strong>Kommentare:</strong> <br/>
                  {group.productComments.map(comment => (
                      <React.Fragment key={comment}>
                        {comment} <br/>
                      </React.Fragment>
                  ))}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
        )}
      </Table>
  )
}
