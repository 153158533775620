import {AxiosInstance} from "axios";
import {OrderDay} from "../types/CustomerApiTypes";
import {CustomerService} from "./CustomerService";

export class OrderDayCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/order-days')
  }

  getAvailableOrderDays = (): Promise<OrderDay[]> => {
    return this.httpClient.get('/available')
  }

}
