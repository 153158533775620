import {Route, useHistory} from "react-router";
import React from "react";
import {Button, Header, Icon, Menu, MenuItem, Modal, Popup} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {downloadKitchenOverviewDocument} from "../utils/KitchenTimeslotGroupsDocument";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {useRoutePaths} from "../context/RoutePathsContext";
import {DateRangeForm} from "../../common/components/lib/DateRangeForm";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import {DriverMultipleDropdown} from "./orders/DriverDropdown";
import {OrderViewProps} from "./orders/orderview/OrderViewProps";
import {Responsive} from "../../common/components/lib/Responsive";

export function OrderViewMenu(props: OrderViewProps) {
  const {ordersState} = props;

  const search = useHistory().location.search;
  const routePaths = useRoutePaths().employee.orders

  return (
      <React.Fragment>
        <Menu stackable={true} secondary={false} pointing={false} size={"large"}>
          <Menu.Item as={NavLink} to={{pathname: routePaths.service(), search}}>Service</Menu.Item>
          <Menu.Item as={NavLink} to={{pathname: routePaths.kitchen(), search}}>Küche</Menu.Item>
          <Menu.Item as={NavLink} to={{pathname: routePaths.delivery(), search}}>Liefern</Menu.Item>
          <Menu.Item as={NavLink} to={{pathname: routePaths.cards(), search}}>Karten</Menu.Item>
          <Menu.Menu position='right'>
            <Route path={routePaths.kitchen()}>
              <Menu.Item>
                <Button
                    icon={"file pdf"} color={"red"} basic={true}
                    onClick={() => downloadKitchenOverviewDocument(ordersState.orders)}
                    disabled={ordersState.isEmpty}
                />
              </Menu.Item>
            </Route>
            <MenuItem>
              <Responsive smallerThan={"computer"}>
                <OrderFiltersModal {...props} />
              </Responsive>
              <Responsive biggerOrEqual={"computer"}>
                <OrderFiltersPopup {...props}/>
              </Responsive>
            </MenuItem>
          </Menu.Menu>
        </Menu>
        <Divider hidden={true}/>
      </React.Fragment>
  )
}

function OrderFiltersPopup(props: OrderViewProps) {
  return (
      <Popup on={"click"} trigger={<Button basic={!props.ordersState.filterSettings.isAnyFilterActive} icon={"filter"}/>} position={"bottom left"}>
        <OrderFilterContent {...props}/>
      </Popup>
  )

}

function OrderFiltersModal(props: OrderViewProps) {
  return (
      <Modal
          size={"mini"} trigger={<Button basic={!props.ordersState.filterSettings.isAnyFilterActive} icon={"filter"}/>} closeOnDimmerClick={true}
          dimmer={"inverted"}
      >
        <Modal.Content>
          <OrderFilterContent {...props}/>
        </Modal.Content>
      </Modal>
  )
}

function OrderFilterContent(props: OrderViewProps) {
  const {ordersState} = props;

  return (
      <React.Fragment>
        <Header textAlign={"center"}> Bestellungen filtern</Header>
        <DateRangeForm
            start={ordersState.filterSettings.start}
            end={ordersState.filterSettings.end}
            setStart={ordersState.filterSettings.setStart}
            setEnd={ordersState.filterSettings.setEnd}
        />
        <Divider/>
        <DriverMultipleDropdown
            drivers={props.drivers} selectedDriverIds={ordersState.filterSettings.selectedDriverIds}
            onChange={ordersState.filterSettings.setSelectedDriverIds}
        />
        <Divider/>
        <React.Fragment>
          <Icon name={"bell"} color={"teal"}/>
          <Checkbox
              style={{marginBottom: 15}} label={"Nur neue anzeigen"} checked={ordersState.filterSettings.displayOnlyNew}
              onChange={ordersState.filterSettings.toggleDisplayOnlyNew}
          />
          <br/>
        </React.Fragment>
        <React.Fragment>
          <Icon name={"check"} color={"green"}/>
          <Checkbox
              style={{marginBottom: 15}} label={"Erledigte anzeigen"} checked={ordersState.filterSettings.displayDone}
              onChange={ordersState.filterSettings.toggleDisplayDone}
              disabled={ordersState.filterSettings.displayOnlyNew}
          />
          <br/>
        </React.Fragment>
        <React.Fragment>
          <Icon name={"x"} color={"red"}/>
          <Checkbox
              label={"Stornierte anzeigen"} checked={ordersState.filterSettings.displayCanceled}
              onChange={ordersState.filterSettings.toggleDisplayCanceled}
              disabled={ordersState.filterSettings.displayOnlyNew}
          />
        </React.Fragment>
      </React.Fragment>
  )
}
