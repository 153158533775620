import React from "react";
import {OrderTypeSelector} from "../common/OrderTypeSelector";
import {Container, Divider, Header} from "semantic-ui-react";
import {OrderType} from "../../../../admin/pages/OrdersPage";
import {OrderProgressIndicator} from "../common/OrderProgressIndicator";
import {SubmitStatus} from "../types/SubmitStatus";

interface Props {
  selectOrderType: (orderType: OrderType) => any;
}

export function DeliveryCurrentlyNotAvailableMessage(props: Props) {
  return (
      <React.Fragment>
        <OrderProgressIndicator
            basePath={""} submitStatus={SubmitStatus.NotSubmitted} isTimeslotFormDataValid={false} isProductsFormDataValid={false}
            isCustomerFormDataValid={false}
        />
        <Divider hidden={true}/>
        <OrderTypeSelector orderType={"DELIVERY"} selectOrderType={props.selectOrderType}/>
        <Divider hidden={true}/>
        <Container text={true} textAlign={"center"}><Header>Derzeit ist keine Lieferung möglich.</Header></Container>
      </React.Fragment>
  )
}
