import React from "react";
import {Header} from "semantic-ui-react";
import {AddressForm} from "./AddressForm";
import {AddressFormData} from "../hooks/UseAddressFormData";

interface Props {
  addressFormData: AddressFormData;
}

export function AddressSummary(props: Props) {

  return (
      <React.Fragment>
        <Header as={"h3"}>Lieferadresse</Header>
        <AddressForm addressFormData={props.addressFormData} isReadonly={true}/>
      </React.Fragment>
  )

}
