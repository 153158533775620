import {AxiosInstance} from "axios";
import {Shop, ShopUpdate} from "../types/AdminApiTypes";
import {AdminService} from "./AdminService";

export class ShopEmployeeService extends AdminService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/shop')
  }

  getShop = (): Promise<Shop> => {
    return this.httpClient.get('')
  }

  updateShop = (shopUpdate: ShopUpdate): Promise<void> => {
    return this.httpClient.put('', shopUpdate)
  }

}
