import React from "react";
import {Header} from "semantic-ui-react";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {BackButton} from "../common/BackButton";
import {CustomerSummary} from "../common/CustomerSummary";
import {OrderItemsSummary} from "../common/OrderItemsSummary";
import {AddressSummary} from "./AddressSummary";
import {DeliveryDataSummary} from "./DeliveryDataSummary";
import {NextButton} from "../common/NextButton";
import {CustomerFormData} from "../hooks/UseCustomerFormData";
import {AddressFormData} from "../hooks/UseAddressFormData";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";
import {ProductsFormData} from "../hooks/UseProductsFormData";
import {SubmitStatus} from "../types/SubmitStatus";
import {NavigationButtonGrid} from "../common/NavigationButtonGrid";

interface Props {
  submitStatus: SubmitStatus;
  customerFormData: CustomerFormData;
  addressFormData: AddressFormData;
  timeslotFormData: TimeslotFormData;
  productsFormData: ProductsFormData;
  totalAmount: number;
  deliveryFee: number;
  freeDeliveryThreshold?: number;
  back: () => any;
  submit: () => any;
}

export function DeliverySummaryPage(props: Props) {

  const {submit, back, customerFormData, addressFormData, timeslotFormData, productsFormData, submitStatus} = props;

  function isPending(): boolean {
    return submitStatus === SubmitStatus.InProgress;
  }

  return (
      <React.Fragment>
        <Header as={'h2'} content={'Zusammenfassung'}/>
        <Divider hidden={true}/>
        <CustomerSummary customerFormData={customerFormData}/>
        <Divider hidden={true}/>
        <AddressSummary addressFormData={addressFormData}/>
        <Divider hidden={true}/>
        <DeliveryDataSummary timeslotFormData={timeslotFormData}/>
        <Divider hidden={true}/>
        <OrderItemsSummary
            productsFormData={productsFormData} totalAmount={props.totalAmount} deliveryFee={props.deliveryFee}
            freeDeliveryThreshold={props.freeDeliveryThreshold}
        />
        <Divider hidden={true}/>
        <NavigationButtonGrid
            backButton={<BackButton onClick={back} disabled={false}/>}
            nextButton={<NextButton onClick={submit} disabled={isPending()} loading={isPending()} text={"Zahlungspflichtig bestellen"}/>}
        />
      </React.Fragment>
  )

}
