import React from "react";
import MediaQuery from "react-responsive";

const breakpoints = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}


type Device = keyof typeof breakpoints;


interface Props {
  biggerOrEqual?: Device;
  smallerThan?: Device;
  children: JSX.Element;
}

export function Responsive(props: Props) {

  function getMinDeviceWidth(): number {
    return props.biggerOrEqual ? breakpoints[props.biggerOrEqual] : 0;
  }

  function getMaxDeviceWidth(): number {
    return props.smallerThan ? breakpoints[props.smallerThan] - 1 : Number.MAX_SAFE_INTEGER;
  }

  return (
      <MediaQuery minDeviceWidth={getMinDeviceWidth()} maxDeviceWidth={getMaxDeviceWidth()}>{props.children}</MediaQuery>
  )

}
