import {HttpClient} from "../../common/services/HttpClient";
import {AxiosHttpClient} from "../../common/services/AxiosHttpClient";
import {AxiosInstance} from "axios";

export abstract class AdminService {

  protected httpClient: HttpClient

  protected constructor(axiosInstance: AxiosInstance, shop: string, path: string) {
    this.httpClient = new AxiosHttpClient(axiosInstance, `/${shop}/employee/${path}`)
  }

}
