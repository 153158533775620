import {OrderItemDto} from "../../types/AdminApiTypes";
import React from "react";

interface Props {
  orderItem: OrderItemDto
}

export function OrderItem(props: Props) {

  return (
      <span>{props.orderItem.amount}x {props.orderItem.product.name}</span>
  )

}
