import React from "react";
import {Header} from "semantic-ui-react";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";

interface Props {
  timeslotFormData: TimeslotFormData;
}

export function PickupDataSummary(props: Props) {
  const {timeslotFormData} = props;
  if (!timeslotFormData.isValid) {
    return <div>Unvollständige Informationen.</div>
  }
  const date = timeslotFormData.selectedOrderDay.formattedDate;
  const start = timeslotFormData.selectedTimeslot.formattedStart;
  const end = timeslotFormData.selectedTimeslot.formattedEnd;
  return (
      <React.Fragment>
        <Header as={'h3'}>Abholung</Header>
        <div>Am <strong>{date}</strong> zwischen <strong>{start} - {end}</strong> Uhr.</div>
      </React.Fragment>
  )

}
