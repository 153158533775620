import {Button} from "semantic-ui-react";
import React from "react";
import {OrderType} from "../../../../admin/pages/OrdersPage";

interface Props {
  orderType: OrderType;
  selectOrderType: (orderType: OrderType) => any;
}

export function OrderTypeSelector(props: Props) {

  const coloredStyle = {color: 'red'};
  const pickupStyles = props.orderType === 'PICKUP' ? coloredStyle : {};
  const deliveryStyles = props.orderType === 'DELIVERY' ? coloredStyle : {};

  return (
      <Button.Group fluid={true} size='large'>
        <Button {...pickupStyles} onClick={() => props.selectOrderType("PICKUP")}>Abholung</Button>
        <Button {...deliveryStyles} onClick={() => props.selectOrderType("DELIVERY")}>Lieferung</Button>
      </Button.Group>
  )
}
