import {StompConnection} from "./StompClient";

export interface OrderEvent {
  orderId: number;
  timestamp: string;
  formattedTimestamp: string;
}

export class OrderEvents {

  constructor(private connection: StompConnection) {
  }

  subscribe(handleEvent: (event: OrderEvent) => any): () => void {
    const subscription = this.connection.subscribe("/topic/orders", message => {
      handleEvent(message as OrderEvent);
    })
    return subscription.unsubscribe;
  }

}
