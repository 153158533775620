import {Button, Grid, Icon, Table} from "semantic-ui-react";
import React from "react";
import {OrderLabels} from "../OrderLabels";
import {DriverDropdown} from "../DriverDropdown";
import {ToggleDoneButton} from "../OrderActionButtons";
import {EnhancedOrder} from "../../../hooks/UseOrdersState";
import {DeliveryAddressDtoRes, DriverDto} from "../../../types/AdminApiTypes";
import {OptionalComment} from "./OptionalComment";
import {OrderItemList} from "../OrderItemList";
import {TimeslotGroup} from "./DeliveryOrderView";
import {useAdminContext} from "../../../context/AdminContext";
import {Responsive} from "../../../../common/components/lib/Responsive";
import {OptionalDeliveryFeeInfo} from "../OrderCard";
import {PackagingOptionLabel} from "../PackagingOptionLabel";

const START_END_ADDRESS: DeliveryAddressDtoRes = {
  street: "Schönbrunnerstraße 235",
  zipcode: "1120",
}

interface TableProps {
  timeslotGroups: TimeslotGroup[];
  drivers: DriverDto[];
}

export function DeliveryOrderTable(props: TableProps) {
  return (
      <Table celled>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pos.</Table.HeaderCell>
              <Table.HeaderCell>Kundendaten</Table.HeaderCell>
              <Table.HeaderCell>Lieferadresse</Table.HeaderCell>
              <Table.HeaderCell>Produkte</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Gesamtbetrag</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Labels</Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"}>Fahrer</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>Aktionen</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Responsive>
        <Table.Body>
          {props.timeslotGroups.map(timeslotGroup => {
            const key = `${timeslotGroup.details.timeslotId}-${timeslotGroup.details.start}`
            return <TimeslotGroupRows key={key} timeslotGroup={timeslotGroup} drivers={props.drivers}/>
          })}
        </Table.Body>
      </Table>
  )
}

function createGoogleMapsRouteLink(orders: EnhancedOrder[]): string {
  let url = "https://www.google.at/maps/dir";
  const addresses = [START_END_ADDRESS, ...orders.map(order => order.deliveryDetails?.address), START_END_ADDRESS];
  for (let address of addresses) {
    if (!address) continue;
    const concatenatedAddress = `${address.street}+${address.zipcode}+Wien`
    .replace(/\s+?/g, "+")
    .replace(/\//g, "+");
    url += "/" + concatenatedAddress;
  }
  return url;
}

interface TimeslotGroupRowsProps {
  timeslotGroup: TimeslotGroup;
  drivers: DriverDto[];
}

function TimeslotGroupRows(props: TimeslotGroupRowsProps) {

  const {timeslotGroup} = props;

  const orders = timeslotGroup.orders.sort((a, b) => {
    return (a.deliveryDetails?.rank ?? 0) - (b.deliveryDetails?.rank ?? 0);
  });

  function StartAndEnd() {
    return <strong>{timeslotGroup.details.formattedStart} - {timeslotGroup.details.formattedEnd}</strong>
  }

  function MapButton() {
    return (
        <Button
            icon={"map"}
            size={"large"}
            color={"blue"}
            as={"a"}
            href={createGoogleMapsRouteLink(orders)}
            target={"_blank"}
        />
    )
  }

  return (
      <React.Fragment>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Row>
            <Table.Cell colSpan={7}>
              <StartAndEnd/>
            </Table.Cell>
            <Table.Cell textAlign={"center"}>
              <MapButton/>
            </Table.Cell>
          </Table.Row>
        </Responsive>
        <Responsive smallerThan={"tablet"}>
          <Table.Row>
            <Table.Cell colSpan={8}>
              <Grid>
                <Grid.Row verticalAlign={"middle"}>
                  <Grid.Column width={12}>
                    <StartAndEnd/>
                  </Grid.Column>
                  <Grid.Column>
                    <MapButton/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Table.Cell>
          </Table.Row>
        </Responsive>
        {orders.map(order => (
            <OrderRow key={order.id} order={order} drivers={props.drivers}/>
        ))}
      </React.Fragment>
  )
}

interface OrderRowProps {
  order: EnhancedOrder;
  drivers: DriverDto[];
}

function OrderRow(props: OrderRowProps) {

  const {order} = props;

  const accessQuery = useAdminContext().accessQuery;

  return (
      <Table.Row>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Cell textAlign={"center"}>
            <Grid>
              <Grid.Row textAlign={"center"} verticalAlign={"bottom"}>
                <Grid.Column>
                  <Icon name={"arrow up"} circular={true} onClick={() => order.updateDeliveryRank((order.deliveryDetails?.rank ?? 0) - 1)}/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign={"center"} verticalAlign={"middle"}>
                <Grid.Column>
                  {order.deliveryDetails?.rank}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign={"center"} verticalAlign={"top"}>
                <Grid.Column>
                  <Icon name={"arrow down"} circular={true} onClick={() => order.updateDeliveryRank((order.deliveryDetails?.rank ?? 0) + 1)}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
        </Responsive>
        <Table.Cell>
          <span>{order.customer.firstName} {order.customer.lastName}</span> <br/>
          <span>{order.customer.emailAddress}</span> <br/>
          <span>{order.customer.phoneNumber}</span> <br/>
        </Table.Cell>
        {order.type === "DELIVERY" && (
            <Table.Cell>
              <span>{order.deliveryDetails?.address!!.zipcode}, {order.deliveryDetails?.address?.street}</span> <br/>
              <OptionalComment value={order.deliveryDetails?.address.comment}/>
            </Table.Cell>
        )}
        <Table.Cell width={8}>
          <OrderItemList orderItems={order.orderItems}/>
          <OptionalComment value={order.productsComment} includeLineBreak={true}/>
          <PackagingOptionLabel packagingOption={order.packagingOption} amount={order.itemsCount}/>
        </Table.Cell>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Cell textAlign={"center"}>{order.formattedTotalAmount} € <br/><OptionalDeliveryFeeInfo order={order}/></Table.Cell>
        </Responsive>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Cell textAlign={"center"}>
            <OrderLabels order={order}/>
          </Table.Cell>
        </Responsive>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Cell textAlign={"right"}>
            <DriverDropdown
                drivers={props.drivers} selectedDriverId={order.deliveryDetails?.driverId} onChange={order.updateDriver}
                disabled={!accessQuery.canUpdateOrderDriver()}
            />
          </Table.Cell>
        </Responsive>
        <Responsive biggerOrEqual={"tablet"}>
          <Table.Cell textAlign={"center"}>
            <ToggleDoneButton order={order}/>
          </Table.Cell>
        </Responsive>
        <Responsive smallerThan={"tablet"}>
          <Table.Cell>
            <Grid>
              <Grid.Row columns={2} verticalAlign={"middle"}>
                <Grid.Column>
                  <strong>{order.formattedTotalAmount} € <br/><OptionalDeliveryFeeInfo order={order}/></strong>
                </Grid.Column>
                <Grid.Column width={4} floated={"right"}>
                  <ToggleDoneButton order={order}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
        </Responsive>
      </Table.Row>
  )
}