import {Filter} from "./Filter";
import {ProductDto} from "../types/AdminApiTypes";

export class HideNotActiveProductsFilter implements Filter<ProductDto> {

  constructor(private config: { enabled: boolean }) {
  }

  shouldBeFiltered(element: ProductDto): boolean {
    if (!this.config.enabled) return false;
    return !element.active;
  }

}
