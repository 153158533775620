import {Filter} from "./Filter";
import {OrderDto} from "../types/AdminApiTypes";
import moment from "moment";
import {EnhancedOrder} from "../hooks/UseOrdersState";

export class OrderStatusDoneFilter implements Filter<OrderDto> {

  constructor(private config: { disabled: boolean }) {
  }

  shouldBeFiltered(element: OrderDto): boolean {
    if (this.config.disabled) return false;
    return element.status === "DONE";
  }

}

export class OrderDateRangeStartFilter implements Filter<OrderDto> {

  constructor(private dateRangeStart: Date | null) {
  }

  shouldBeFiltered(element: OrderDto): boolean {
    if (this.dateRangeStart === null) return false;
    return moment(element.timeslot.start).isBefore(moment(this.dateRangeStart).startOf("day"));
  }

}

export class OrderDateRangeEndFilter implements Filter<OrderDto> {

  constructor(private dateRangeEnd: Date | null) {
  }

  shouldBeFiltered(element: OrderDto): boolean {
    if (this.dateRangeEnd === null) return false;
    return moment(element.timeslot.start).isAfter(moment(this.dateRangeEnd).endOf("day"));
  }

}

export class OrderOnlyNewFilter implements Filter<EnhancedOrder> {

  constructor(private config: { disabled: boolean }) {
  }

  shouldBeFiltered(element: EnhancedOrder): boolean {
    if (this.config.disabled) return false;
    return !element.isNew();
  }

}

export class OrderHideCanceledFilter implements Filter<EnhancedOrder> {

  constructor(private config: { disabled: boolean }) {
  }

  shouldBeFiltered(element: EnhancedOrder): boolean {
    if (this.config.disabled) return false;
    return element.status === "CANCELED";
  }

}

export class ShowOnlySelectedDriversFilter implements Filter<EnhancedOrder> {

  constructor(private config: { disabled: boolean, driverIds: number[] }) {
  }

  shouldBeFiltered(element: EnhancedOrder): boolean {
    if (this.config.disabled || element.type == "PICKUP") return false;
    const ids = this.config.driverIds.map(id => Number(id));
    return !ids.some(id => id === Number(element.deliveryDetails?.driverId))
  }

}
