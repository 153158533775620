import React, {useEffect, useState} from "react";
import {Container, Divider, Loader} from "semantic-ui-react";
import {DeliveryOrderFormData, OrderForm as GenericOrderForm, PickupOrderFormData} from "../../common/components/order-form/OrderForm";
import {DeliveryArea, DeliveryOrder, OrderDay, PackagingOption, PickupOrder, Product, Shop} from "../types/CustomerApiTypes";
import {SubmitStatus} from "../../common/components/order-form/types/SubmitStatus";
import {FailureView} from "../components/FailureView";
import {SuccessView} from "../components/SuccessView";
import {useCustomerComponents, useCustomerRoutePaths, useCustomerServices} from "../context/CustomerAppContext";
import {OrderingNotPossibleView} from "../components/OrderingNotPossibleView";


export function OrderPage() {

  const {LogoBanner} = useCustomerComponents()
  const {orderDayService, deliveryService, productService, orderService, packagingOptionsService, shopService} = useCustomerServices()
  const routePaths = useCustomerRoutePaths()

  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.NotSubmitted);
  const [initialized, setInitialized] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryArea[]>([]);
  const [orderDays, setOrderDays] = useState<OrderDay[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [packagingOptions, setPackagingOptions] = useState<PackagingOption[]>([])
  const [shop, setShop] = useState<Shop>()

  function initialize() {
    setInitialized(false)
    const setToInitialized = () => setInitialized(true);
    Promise.all([
      orderDayService.getAvailableOrderDays().then(setOrderDays),
      deliveryService.getDeliveryAreas().then(setDeliveryAreas),
      productService.getProducts().then(setAvailableProducts),
      packagingOptionsService.getPackagingOptions().then(setPackagingOptions),
      shopService.getShop().then(setShop),
    ]).then(setToInitialized).catch(setToInitialized)
  }

  function submit(block: () => Promise<void>) {
    setSubmitStatus(SubmitStatus.InProgress);
    block().then(() => {
      setSubmitStatus(SubmitStatus.Success);
    }).catch(() => {
      setSubmitStatus(SubmitStatus.Failure);
    });
  }

  function placePickupOrder(formData: PickupOrderFormData) {
    const {customerFormData, timeslotFormData, productsFormData} = formData;
    const order: PickupOrder = {
      customer: customerFormData.getCustomer(),
      orderDayId: timeslotFormData.selectedOrderDay.id,
      timeslotId: timeslotFormData.selectedTimeslot.id,
      orderItems: productsFormData.orderItems,
      productsComment: productsFormData.productsComment,
      packagingOptionId: productsFormData.selectedPackagingOption.id,
    }
    submit(() => orderService.placePickupOrder(order))
  }

  function placeDeliveryOrder(formData: DeliveryOrderFormData) {
    const {customerFormData, timeslotFormData, productsFormData, deliveryAddressFormData} = formData;
    const order: DeliveryOrder = {
      customer: customerFormData.getCustomer(),
      orderDayId: timeslotFormData.selectedOrderDay.id,
      timeslotId: timeslotFormData.selectedTimeslot.id,
      orderItems: productsFormData.orderItems,
      productsComment: productsFormData.productsComment,
      deliveryAddress: deliveryAddressFormData.getAddress(),
      packagingOptionId: productsFormData.selectedPackagingOption.id,
    }
    submit(() => orderService.placeDeliveryOrder(order))
  }

  function isOrderingNotPossible(): boolean {
    return !shop?.isOrderingEnabled || availableProducts.length === 0 || orderDays.length === 0;
  }

  function OrderForm() {
    return (
        <GenericOrderForm
            basePath={routePaths.root()}
            submitPickup={placePickupOrder}
            submitDelivery={placeDeliveryOrder}
            submitStatus={submitStatus}
            orderDays={orderDays}
            products={availableProducts}
            deliveryAreas={deliveryAreas}
            packagingOptions={packagingOptions}
        />
    )
  }

  function PageBody() {
    if (!initialized || submitStatus == SubmitStatus.InProgress) {
      return <Loader active={true}/>
    } else if (isOrderingNotPossible()) {
      return <OrderingNotPossibleView reload={initialize}/>
    } else if (submitStatus === SubmitStatus.Failure) {
      return <FailureView/>
    } else if (submitStatus === SubmitStatus.Success) {
      return <SuccessView basePath={routePaths.root()}/>
    } else {
      return <OrderForm/>
    }
  }

  useEffect(initialize, []);

  return (
      <React.Fragment>
        <LogoBanner/>
        <Divider hidden={true}/>
        <Container text={true}>
          <PageBody/>
          <Divider hidden={true}/>
          <Divider hidden={true}/>
        </Container>
      </React.Fragment>
  )

}
