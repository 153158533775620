import {useEffect, useState} from "react";
import {DeliveryDetailsUpdateDto, OrderDto} from "../types/AdminApiTypes";
import moment from "moment";
import {
  OrderDateRangeEndFilter,
  OrderDateRangeStartFilter,
  OrderHideCanceledFilter,
  OrderOnlyNewFilter,
  OrderStatusDoneFilter,
  ShowOnlySelectedDriversFilter
} from "../utils/OrderFilters";
import {useFilters} from "./UseFilters";
import {OrderAdminService} from "../services/OrderAdminService";
import {useLocalStorage, useLocalStorageDate} from "../../common/hooks/UseLocalStorage";

export interface EnhancedOrder extends OrderDto {
  isNew: () => boolean;
  isPast: () => boolean;
  updateStatus: (status: "DONE" | "OPEN") => Promise<any>;
  cancel: () => Promise<any>;
  readyTime: Date,
  formattedReadyTime: string;
  updateDriver: (driverId: number | undefined) => Promise<any>;
  updateDeliveryRank: (rank: number) => Promise<any>;
  itemsCount: number;
}

export interface OrdersState {
  orders: EnhancedOrder[];
  initialized: boolean;
  isEmpty: boolean;
  filterSettings: FilterSettings;
  getOrder: (id: number) => EnhancedOrder,
  refresh: () => void;
}

export interface FilterSettings {
  toggleDisplayDone: () => void;
  displayDone: boolean;
  start: Date | null;
  end: Date | null;
  setStart: (date: Date | null) => void;
  setEnd: (date: Date | null) => void;
  displayOnlyNew: boolean;
  toggleDisplayOnlyNew: () => void;
  displayCanceled: boolean;
  toggleDisplayCanceled: () => void;
  selectedDriverIds: number[];
  setSelectedDriverIds: (ids: number[]) => void;
  isAnyFilterActive: boolean;
}

export function useOrdersState(service: OrderAdminService): OrdersState {

  const [initialized, setInitialized] = useState(false);
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [displayDone, setDisplayDone] = useLocalStorage('ordersDisplayDone', false);
  const [start, setStart] = useLocalStorageDate("ordersStartDate", new Date())
  const [end, setEnd] = useLocalStorageDate("ordersEndDate", new Date())
  const [displayOnlyNew, setDisplayOnlyNew] = useLocalStorage('ordersDisplayOnlyNew', true);
  const [displayCanceled, setDisplayCanceled] = useLocalStorage('ordersDisplayCancelled', false);
  const [selectedDrivers, setSelectedDrivers] = useLocalStorage<number[]>('ordersSelectedDriverIds', []);

  function initializeOrders() {
    service.getAllOrders().then(orders => {
      setOrders(orders);
      setInitialized(true);
    });
  }

  function replaceOrder(id: number, block: (previousOrder: OrderDto) => OrderDto) {
    const orderIndex = orders.findIndex(order => Number(order.id) === Number(id));
    const previousOrder = orders[orderIndex];
    const updatedOrder = block(previousOrder);
    const updatedOrders = [...orders]
    updatedOrders[orderIndex] = updatedOrder;
    setOrders(updatedOrders);
  }

  function updateOrderStatus(id: number, status: "OPEN" | "DONE"): Promise<any> {
    return service.updateStatus(id, {status}).then(() => {
      replaceOrder(id, previousOrder => ({...previousOrder, status}))
    })
  }

  function cancelOrder(id: number): Promise<any> {
    return service.cancelOrder(id).then(() => {
      replaceOrder(id, previousOrder => ({...previousOrder, status: "CANCELED"}))
    })
  }

  function updateDeliveryDetails(id: number, details: DeliveryDetailsUpdateDto): Promise<any> {
    return service.updateDeliveryDetails(id, details).then(updatedOrder => {
      replaceOrder(id, () => (updatedOrder))
    })
  }

  function enhanceOrder(orderDto: OrderDto): EnhancedOrder {
    const readyTime = orderDto.type === "DELIVERY" ?
        moment(orderDto.timeslot.start).subtract(20, "minutes").toDate() :
        new Date(orderDto.timeslot.start);
    return {
      ...orderDto,
      isNew: () => {
        const orderDate = moment(orderDto.orderDate);
        return orderDate.isAfter(moment().add(-30, "minutes"));
      },
      updateDeliveryRank(rank: number): Promise<any> {
        return updateDeliveryDetails(orderDto.id, {driverId: orderDto.deliveryDetails?.driverId, rank});
      },
      updateStatus: status => updateOrderStatus(orderDto.id, status),
      isPast: () => moment(orderDto.timeslot.end).isBefore(moment()),
      readyTime,
      formattedReadyTime: moment(readyTime).format("dd, DD.MM.yyyy HH:mm"),
      cancel: () => cancelOrder(orderDto.id),
      updateDriver: (driverId) => updateDeliveryDetails(orderDto.id, {
        driverId,
        rank: orderDto.deliveryDetails?.rank
      }),
      itemsCount: orderDto.orderItems.reduce((sum, item) => sum + item.amount, 0),
    }
  }

  function getOrder(id: number): EnhancedOrder {
    const order = orders.find(order => Number(order.id) === Number(id))
    if (!order) throw new Error(`Order with id=${id} could not be found.`)
    return enhanceOrder(order);
  }

  const enhancedOrders = orders.map(enhanceOrder);

  const enhancedFilteredOrders = useFilters(enhancedOrders, [
    new OrderDateRangeStartFilter(start),
    new OrderDateRangeEndFilter(end),
    new OrderStatusDoneFilter({disabled: displayDone}),
    new OrderOnlyNewFilter({disabled: !displayOnlyNew}),
    new OrderHideCanceledFilter({disabled: displayCanceled}),
    new ShowOnlySelectedDriversFilter({
      disabled: selectedDrivers.length === 0,
      driverIds: selectedDrivers as number[]
    }),
  ])

  function isFilterActive(): boolean {
    return enhancedOrders.length !== enhancedFilteredOrders.length
  }

  useEffect(initializeOrders, [service]);

  return {
    orders: enhancedFilteredOrders,
    initialized,
    isEmpty: enhancedFilteredOrders.length === 0,
    refresh: initializeOrders,
    getOrder,
    filterSettings: {
      displayDone: displayDone,
      toggleDisplayDone: () => setDisplayDone(!displayDone),
      start,
      end,
      setEnd,
      setStart,
      displayOnlyNew: displayOnlyNew,
      toggleDisplayOnlyNew: () => setDisplayOnlyNew(!displayOnlyNew),
      displayCanceled,
      toggleDisplayCanceled: () => setDisplayCanceled(!displayCanceled),
      setSelectedDriverIds: setSelectedDrivers,
      selectedDriverIds: selectedDrivers,
      isAnyFilterActive: isFilterActive(),
    }
  }

}