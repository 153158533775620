import {useTimeslotFormData, ValidTimeslotFormData} from "./hooks/UseTimeslotFormData";
import {DeliveryArea, OrderDay, PackagingOption, Product} from "../../../customer/types/CustomerApiTypes";
import React from "react";
import {Redirect, Route, Switch, useHistory} from "react-router";
import {PickupOrderForm} from "./pickup/PickupOrderForm";
import {ProductAmount, ProductsFormData, useProductsFormData} from "./hooks/UseProductsFormData";
import {useCustomerFormData, ValidCustomerFormData} from "./hooks/UseCustomerFormData";
import {DeliveryOrderForm} from "./delivery/DeliveryOrderForm";
import {useAddressFormData, ValidAddressFormData} from "./hooks/UseAddressFormData";
import {OrderType} from "../../../admin/pages/OrdersPage";
import {SubmitStatus} from "./types/SubmitStatus";
import {OrderDto} from "../../../admin/types/AdminApiTypes";

export interface PickupOrderFormData {
  timeslotFormData: ValidTimeslotFormData;
  productsFormData: ProductsFormData;
  customerFormData: ValidCustomerFormData;
}

export interface DeliveryOrderFormData {
  timeslotFormData: ValidTimeslotFormData;
  deliveryAddressFormData: ValidAddressFormData;
  productsFormData: ProductsFormData;
  customerFormData: ValidCustomerFormData;
}

export interface Props {
  basePath: string;
  submitStatus: SubmitStatus;
  orderDays: OrderDay[];
  products: Product[];
  packagingOptions: PackagingOption[];
  deliveryAreas: DeliveryArea[];
  submitPickup: (formData: PickupOrderFormData) => any;
  submitDelivery: (formData: DeliveryOrderFormData) => any;
  order?: OrderDto;
}

export function OrderForm(props: Props) {

  const history = useHistory();

  const {order} = props;

  const pickupTimeslotFormData = useTimeslotFormData({
    initialState: {
      selectedOrderDayId: order?.type === "PICKUP" ? order.timeslot.orderDayId : undefined,
      selectedTimeslotId: order?.type === "PICKUP" ? order.timeslot.timeslotId : undefined,
    },
    orderDays: props.orderDays.map(orderDay => {
      return {...orderDay, slots: orderDay.slots.filter(slot => slot.type === "PICKUP")}
    }),
  });

  const deliveryTimeslotFormData = useTimeslotFormData({
    initialState: {
      selectedOrderDayId: order?.type === "DELIVERY" ? order.timeslot.orderDayId : undefined,
      selectedTimeslotId: order?.type === "DELIVERY" ? order.timeslot.timeslotId : undefined,
    },
    orderDays: props.orderDays.map(orderDay => {
      return {...orderDay, slots: orderDay.slots.filter(slot => slot.type === "DELIVERY")}
    }),
  });

  const productsFormData = useProductsFormData({
    initialState: {
      productAmounts: getInitialProductAmounts(),
      productsComment: order?.productsComment,
      selectedPackagingOption: getDefaultPackagingOption(),
    },
    packagingOptions: props.packagingOptions,
  });

  function getInitialProductAmounts(): ProductAmount[] {
    return props.products.map(product => {
      const orderItem = order?.orderItems.find(item => Number(item.product.id) === Number(product.id));
      const amount = orderItem ? orderItem.amount : 0;
      return {product, amount};
    })
  }

  function getDefaultPackagingOption(): PackagingOption {
    const defaultOption = props.packagingOptions.find(option => option.isDefault)
    if (defaultOption) {
      return defaultOption
    } else if (props.packagingOptions.length > 0) {
      return props.packagingOptions[0]
    } else {
      throw Error('No packaging options are available.');
    }
  }

  const customerFormData = useCustomerFormData({
    initialState: {
      firstName: order?.customer.firstName,
      lastName: order?.customer.lastName,
      emailAddress: order?.customer.emailAddress,
      phoneNumber: order?.customer.phoneNumber,
      consented: order !== undefined,
    }
  });

  const deliveryAddressFormData = useAddressFormData({
    initialState: {
      street: order?.deliveryDetails?.address.street,
      zipcode: order?.deliveryDetails?.address.zipcode,
      comment: order?.deliveryDetails?.address.comment,
    },
    deliveryAreas: props.deliveryAreas,
    timeslotFormData: deliveryTimeslotFormData,
  })

  function submitPickup() {
    if (!customerFormData.isValid || !pickupTimeslotFormData.isValid) throw new Error("Can not submit invalid form data.");
    props.submitPickup({customerFormData, productsFormData, timeslotFormData: pickupTimeslotFormData})
  }

  function submitDelivery() {
    if (!customerFormData.isValid || !deliveryTimeslotFormData.isValid || !deliveryAddressFormData.isValid) throw new Error("Can not submit invalid form data.");
    props.submitDelivery({customerFormData, productsFormData, timeslotFormData: deliveryTimeslotFormData, deliveryAddressFormData})
  }

  function selectOrderType(orderType: OrderType) {
    if (orderType === "PICKUP") history.push(`${props.basePath}/pickup`);
    if (orderType === "DELIVERY") history.push(`${props.basePath}/delivery`);
  }

  return (
      <Switch>
        <Route path={`${props.basePath}/pickup`} exact={false}>
          <PickupOrderForm
              basePath={`${props.basePath}/pickup`}
              selectOrderType={selectOrderType}
              navigateTo={to => history.push(`${props.basePath}/pickup/${to}`)}
              submitStatus={props.submitStatus}
              timeslotFormData={pickupTimeslotFormData}
              productsFormData={productsFormData}
              customerFormData={customerFormData}
              submit={submitPickup}
          />
        </Route>
        <Route path={`${props.basePath}/delivery`} exact={false}>
          <DeliveryOrderForm
              basePath={`${props.basePath}/delivery`}
              navigateTo={to => history.push(`${props.basePath}/delivery/${to}`)}
              selectOrderType={selectOrderType}
              submitStatus={props.submitStatus}
              timeslotFormData={deliveryTimeslotFormData}
              productsFormData={productsFormData}
              customerFormData={customerFormData}
              deliveryAddressFormData={deliveryAddressFormData}
              submit={submitDelivery}
          />
        </Route>
        <Redirect to={`${props.basePath}/pickup`}/>
      </Switch>
  )

}
