import {AxiosInstance} from "axios";
import {DriverDto} from "../types/AdminApiTypes";
import {AdminService} from "./AdminService";

export class DriverAdminService extends AdminService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/drivers')
  }

  getAll = (): Promise<DriverDto[]> => {
    return this.httpClient.get('');
  }

}
