import {useEffect} from "react";
import {useLocalStorage} from "../../common/hooks/UseLocalStorage";

export interface Paginator<T> {
  activePage: number;
  changePage: (page: number | string | undefined) => void;
  totalPages: number;
  pageItems: T[];
  togglePagination: () => void;
  enabled: boolean;
}

export interface PaginatorOptions<T> {
  pageSize: number;
  items: T[];
  name: string;
  enabledByDefault: boolean;
}

export function usePaginator<T>(options: PaginatorOptions<T>): Paginator<T> {

  const [activePage, setActivePage] = useLocalStorage(`${options.name}ActivePage`, 1);
  const [enabled, setEnabled] = useLocalStorage(`${options.name}PaginationEnabled`, options.enabledByDefault)

  function changePage(page: number | string | undefined) {
    setActivePage(Number(page));
  }

  function getPageItems(): T[] {
    if (!enabled) return options.items;
    const startIndex = (activePage - 1) * options.pageSize;
    const endIndex = startIndex + options.pageSize;
    return options.items.slice(startIndex, endIndex);
  }

  const totalPages = Math.ceil(options.items.length / options.pageSize);

  useEffect(() => {
    if (totalPages === 1) setActivePage(1)
  }, [totalPages, setActivePage])

  return {
    activePage,
    changePage,
    totalPages,
    pageItems: getPageItems(),
    togglePagination: () => setEnabled(!enabled),
    enabled
  }

}
