import {OrderViewProps} from "./OrderViewProps";
import React, {useMemo} from "react";
import {OrderViewMenu} from "../../OrderViewMenu";
import {DriverDto, TimeslotDetailsDto} from "../../../types/AdminApiTypes";
import {EnhancedOrder} from "../../../hooks/UseOrdersState";
import {DeliveryOrderTable} from "./DeliveryOrderTable";


type Props = {
  drivers: DriverDto[];
} & OrderViewProps;

export function DeliveryOrderView(props: Props) {

  const deliveryOrders = useMemo(() => {
    return props.ordersState.orders.filter(order => order.type === "DELIVERY");
  }, [props.ordersState.orders]);

  const timeslotGroups = groupByTimeslot(deliveryOrders);

  return (
      <React.Fragment>
        <OrderViewMenu {...props}/>
        {deliveryOrders.length > 0 && (
            <DeliveryOrderTable timeslotGroups={timeslotGroups} drivers={props.drivers}/>
        )}
      </React.Fragment>
  )

}

export interface TimeslotGroup {
  details: TimeslotDetailsDto;
  orders: EnhancedOrder[];
}

function groupByTimeslot(orders: EnhancedOrder[]): TimeslotGroup[] {
  const indexedGroups: { [timeslotStart: string]: TimeslotGroup } = {};
  for (let order of orders) {
    const group = indexedGroups[order.timeslot.start] ?? {details: order.timeslot, orders: []};
    indexedGroups[order.timeslot.start] = {...group, orders: [...group.orders, order]};
  }
  return Object.values(indexedGroups).sort((a, b) => new Date(a.details.start).getTime() - new Date(b.details.start).getTime());
}
