import React, {useEffect, useState} from 'react';
import {createAdminInstance, KEYCLOAK, STOMP_CLIENT} from "../Configuration";
import {AdminLayout} from "./components/AdminLayout";
import {AdminContext, AdminContextProvider} from "./context/AdminContext";
import {Loader} from "semantic-ui-react";
import {ProductAdminService} from "./services/ProductAdminService";
import {OrderDayAdminService} from "./services/OrderDayService";
import {DriverAdminService} from "./services/DriverAdminService";
import {OrderAdminService} from "./services/OrderAdminService";
import {AccessQuery} from "./services/AccessQuery";
import {useHistory} from "react-router";
import {DeliveryCustomerService} from "../customer/services/DeliveryCustomerService";
import {OrderDayCustomerService} from "../customer/services/OrderDayCustomerService";
import {PackagingOptionsCustomerService} from "../customer/services/PackagingOptionsCustomerService";
import {ShopEmployeeService} from "./services/ShopEmployeeService";

interface Props {
  shop: string;
}

export function AdminApp(props: Props) {

  const [authenticated, setAuthenticated] = useState(KEYCLOAK.authenticated);
  const [context, setContext] = useState<AdminContext | null>(null);

  const history = useHistory();

  function initializeKeycloak() {
    KEYCLOAK.init({onLoad: "login-required"}).then(value => {
      setAuthenticated(value);
    });
  }

  function initializeContext() {
    if (!authenticated) return;
    const accessQuery = new AccessQuery(KEYCLOAK);
    if (!accessQuery.isEmployee()) history.push("/");
    const connect = STOMP_CLIENT.connect(KEYCLOAK.token as string).then(connection => {
      const axios = createAdminInstance(`Bearer ${KEYCLOAK.token}`);
      setContext({
        stompConnection: connection,
        services: {
          productService: new ProductAdminService(axios, props.shop),
          orderDayService: new OrderDayAdminService(axios, props.shop),
          driverService: new DriverAdminService(axios, props.shop),
          orderService: new OrderAdminService(axios, props.shop),
          deliveryAddressService: new DeliveryCustomerService(axios, props.shop),
          orderDayCustomerService: new OrderDayCustomerService(axios, props.shop),
          packagingOptionsCustomerService: new PackagingOptionsCustomerService(axios, props.shop),
          shopService: new ShopEmployeeService(axios, props.shop),
        },
        accessQuery,
      })
      return connection;
    })
    return () => {
      connect.then(connection => connection.disconnect());
    }
  }

  function logout() {
    KEYCLOAK.logout();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initializeContext, [authenticated]);
  useEffect(initializeKeycloak, []);

  if (!authenticated || context === null) {
    return <Loader active={true}/>
  }

  return (
      <AdminContextProvider context={context}>
        <AdminLayout logout={logout}/>
      </AdminContextProvider>
  );

}

