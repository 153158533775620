import {Button, Popup} from "semantic-ui-react";
import React from "react";
import {EnhancedOrder} from "../../hooks/UseOrdersState";
import {Link} from "react-router-dom";
import {useAdminContext} from "../../context/AdminContext";
import {useRoutePaths} from "../../context/RoutePathsContext";


export function OrderActionButtons(props: { order: EnhancedOrder }) {

  const accessQuery = useAdminContext().accessQuery;
  const routePaths = useRoutePaths().employee;

  const {order} = props;

  const isCanceledOrder = props.order.status === "CANCELED";

  const isDoneOrder = props.order.status === "DONE";


  const isCancelDisabled = isCanceledOrder || isDoneOrder || !accessQuery.canCancelOrder();
  const isEditDisabled = isCanceledOrder || isDoneOrder || !accessQuery.canEditOrder();

  const CancelButton = () => (
      <Popup on={'click'} trigger={<Button size={"large"} icon={"x"} disabled={isCancelDisabled}/>}>
        <Button color={"red"} onClick={order.cancel} disabled={isCancelDisabled}>Unwiderruflich stornieren</Button>
      </Popup>
  )
  return (
      <Button.Group>
        <Button size={"large"} icon={"edit"} as={Link} to={routePaths.orders.order.edit.root(String(order.id))} disabled={isEditDisabled}/>
        <CancelButton/>
        <ToggleDoneButton order={order}/>
      </Button.Group>
  )
}

export function ToggleDoneButton(props: { order: EnhancedOrder }) {

  const accessQuery = useAdminContext().accessQuery;
  const {order} = props;


  const isCanceledOrder = props.order.status === "CANCELED";
  const isDoneOrder = props.order.status === "DONE";
  const notAllowed = isCanceledOrder || !accessQuery.canUpdateOrderStatus();

  const markAsDoneButton = (
      <Button size={"large"} icon={"checkmark"} onClick={() => order.updateStatus("DONE")} disabled={isCanceledOrder || notAllowed}/>
  )

  const markAsOpenButton = (
      <Button size={"large"} icon={"checkmark"} color={"green"} onClick={() => order.updateStatus("OPEN")} disabled={notAllowed}/>
  )

  return isDoneOrder ? markAsOpenButton : markAsDoneButton;

}
