import {OrderViewProps} from "./orderview/OrderViewProps";
import {Checkbox, Pagination, Table} from "semantic-ui-react";
import React from "react";
import {OrderActionButtons} from "./OrderActionButtons";
import {EnhancedOrder} from "../../hooks/UseOrdersState";
import {usePaginator} from "../../hooks/UsePaginator";
import {OrderLabels} from "./OrderLabels";
import {OptionalComment} from "./orderview/OptionalComment";
import {OrderItemList} from "./OrderItemList";
import {OptionalDeliveryFeeInfo} from "./OrderCard";
import {PackagingOptionLabel} from "./PackagingOptionLabel";

export function OrderTable(props: OrderViewProps & { isDelivery: boolean, pageSize: number, name: string }) {

  const paginator = usePaginator({items: props.ordersState.orders, pageSize: props.pageSize, name: props.name, enabledByDefault: false});

  return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>Timeslot</Table.HeaderCell>
            <Table.HeaderCell>Kundendaten</Table.HeaderCell>
            {props.isDelivery && (<Table.HeaderCell>Lieferadresse</Table.HeaderCell>)}
            <Table.HeaderCell>Produkte</Table.HeaderCell>
            <Table.HeaderCell textAlign={"center"}>Gesamtbetrag</Table.HeaderCell>
            <Table.HeaderCell textAlign={"center"}>Labels</Table.HeaderCell>
            <Table.HeaderCell textAlign={"center"} width={3}>Aktionen</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {paginator.pageItems.map(order => (
              <OrderRow key={order.id} order={order}/>)
          )}
        </Table.Body>
        {paginator.totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={props.isDelivery ? 7 : 6} textAlign={"right"}>
                  <Checkbox label={"Pagination"} checked={paginator.enabled} onClick={paginator.togglePagination}/>
                  {paginator.enabled && (
                      <Pagination
                          style={{marginLeft: 10}}
                          activePage={paginator.activePage}
                          totalPages={paginator.totalPages}
                          onPageChange={(_, {activePage}) => paginator.changePage(activePage)}
                      />
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
        )}
      </Table>
  )
}

interface OrderRowProps {
  order: EnhancedOrder;
}

function OrderRow(props: OrderRowProps) {
  const {order} = props;
  return (
      <Table.Row key={order.id} positive={order.status === "DONE"} negative={order.status === "CANCELED"}>
        <Table.Cell width={4}>
          {order.timeslot.formattedStart} - {order.timeslot.formattedEnd}
        </Table.Cell>
        <Table.Cell>
          <span>{order.customer.firstName} {order.customer.lastName}</span> <br/>
          <span>{order.customer.emailAddress}</span> <br/>
          <span>{order.customer.phoneNumber}</span> <br/>
        </Table.Cell>
        {order.type === "DELIVERY" && (<Table.Cell>
          <span>{order.deliveryDetails?.address!!.zipcode} Wien - {order.deliveryDetails?.address?.street}</span> <br/>
          <OptionalComment value={order.deliveryDetails?.address.comment}/>
        </Table.Cell>)}
        <Table.Cell width={8}>
          <OrderItemList orderItems={order.orderItems}/>
          <OptionalComment value={order.productsComment} includeLineBreak={true}/>
          <PackagingOptionLabel packagingOption={order.packagingOption} amount={order.itemsCount}/>
        </Table.Cell>
        <Table.Cell textAlign={"center"}>{order.formattedTotalAmount} € <br/><OptionalDeliveryFeeInfo order={order}/></Table.Cell>
        <Table.Cell textAlign={"center"}>
          <OrderLabels order={order}/>
        </Table.Cell>
        <Table.Cell textAlign={"center"}>
          <OrderActionButtons order={order}/>
        </Table.Cell>
      </Table.Row>
  )
}