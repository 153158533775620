import React from "react";
import {CustomerAppContext, CustomerAppContextProvider, CustomerRoutePaths} from "./context/CustomerAppContext";
import {createCustomerInstance} from "../Configuration";
import {OrderDayCustomerService} from "./services/OrderDayCustomerService";
import {OrderPage} from "./pages/OrderPage";
import {OrderCustomerService} from "./services/OrderCustomerService";
import {ProductsCustomerService} from "./services/ProductsCustomerService";
import {DeliveryCustomerService} from "./services/DeliveryCustomerService";
import {VelaniLogo} from "./components/VelaniLogo";
import {PackagingOptionsCustomerService} from "./services/PackagingOptionsCustomerService";
import {ShopCustomerService} from "./services/ShopCustomerService";

interface Props {
  shop: string;
  basePath: string;
}

export function CustomerApp(props: Props) {

  const {shop} = props;

  function getContext(): CustomerAppContext {
    const axiosInstance = createCustomerInstance()
    return {
      services: {
        orderDayService: new OrderDayCustomerService(axiosInstance, shop),
        orderService: new OrderCustomerService(axiosInstance, shop),
        productService: new ProductsCustomerService(axiosInstance, shop),
        deliveryService: new DeliveryCustomerService(axiosInstance, shop),
        packagingOptionsService: new PackagingOptionsCustomerService(axiosInstance, shop),
        shopService: new ShopCustomerService(axiosInstance, shop),
      },
      components: {
        LogoBanner: VelaniLogo,
      },
      routePaths: createCustomerRoutePaths(props.basePath),
    }
  }

  return (
      <CustomerAppContextProvider context={getContext()}>
        <OrderPage/>
      </CustomerAppContextProvider>
  )
}

function createCustomerRoutePaths(basePath: string): CustomerRoutePaths {
  return {
    root: () => basePath,
  }
}
