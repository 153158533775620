import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {StandaloneApp} from "./App";
import {BrowserRouter} from "react-router-dom";
import {TopLevelErrorFallback} from "./common/components/TopLevelErrorFallback";

if (process.env.NODE_ENV === "production") {
  Sentry.init({dsn: "https://a4795cc5d88f403ca6e1227368d826df@o374550.ingest.sentry.io/5510314"});
}


function App() {

  return (
      <StandaloneApp shop={"velani"}/>
  )

}


function Root() {
  return (
      <Sentry.ErrorBoundary fallback={<TopLevelErrorFallback/>}>
        <BrowserRouter basename="/">
            <App/>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
  )

}

ReactDOM.render(<Root/>, document.getElementById('root'));
