import {AxiosInstance} from "axios";
import {CustomerService} from "./CustomerService";
import {DeliveryArea} from "../types/CustomerApiTypes";

export class DeliveryCustomerService extends CustomerService {

  constructor(axiosInstance: AxiosInstance, shop: string) {
    super(axiosInstance, shop, '/delivery')
  }

  getDeliveryAreas = (): Promise<DeliveryArea[]> => {
    return this.httpClient.get('/areas')
  }

}
