import {Button, Divider, Header, Segment} from "semantic-ui-react";
import React from "react";


interface Props {
  reload: () => any;
}

export function OrderingNotPossibleView(props: Props) {
  return (
      <Segment placeholder={true}>
        <Header icon={true}>
          Derzeit können keine Bestellungen aufgegeben werden.
          <br/> Bitte kontaktiert uns unter <a href={"mailto:info@velani.at"}>info@velani.at</a> oder <a href={"tel:+43 1 810 6042"}>+43 1 810
          6042</a>
        </Header>
        <Divider hidden={true}/>
        <Button color={"red"} onClick={props.reload}>Erneut laden</Button>
      </Segment>
  )
}