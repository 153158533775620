import React from "react";
import {Divider, Header} from "semantic-ui-react";
import {TimeslotForm} from "../common/TimeslotForm";
import {NextButton} from "../common/NextButton";
import {AddressForm} from "./AddressForm";
import {OrderTypeSelector} from "../common/OrderTypeSelector";
import {TimeslotFormData} from "../hooks/UseTimeslotFormData";
import {AddressFormData} from "../hooks/UseAddressFormData";
import {OrderType} from "../../../../admin/pages/OrdersPage";
import {NavigationButtonGrid} from "../common/NavigationButtonGrid";
import {OrderDay} from "../../../../customer/types/CustomerApiTypes";

interface Props {
  timeslotFormData: TimeslotFormData;
  addressFormData: AddressFormData;
  selectOrderType: (orderType: OrderType) => any;
  next: () => any;
}

export function OrderTypeFormAndTimeslotFormAndAddressFormPage(props: Props) {

  const {timeslotFormData, addressFormData, next, selectOrderType} = props;

  function getSelectedOrderDayWithFilteredTimeslots(): OrderDay | undefined {
    const selected = timeslotFormData.selectedOrderDay
    if (!selected || !addressFormData.isValid) return undefined;
    return {...selected, slots: selected.slots.filter(slot => slot?.allowedZipcodes?.includes(addressFormData.zipcode) ?? true)}
  }

  return (
      <React.Fragment>
        <OrderTypeSelector orderType={"DELIVERY"} selectOrderType={selectOrderType}/>
        <Divider hidden={true}/>
        <Header as={"h2"}>Lieferadresse</Header>
        <AddressForm isReadonly={false} addressFormData={addressFormData}/>
        <Divider hidden={true}/>
        <Header as={"h2"}>Liefertermin</Header>
        {addressFormData.isValid && (
            <TimeslotForm
                selectedOrderDay={getSelectedOrderDayWithFilteredTimeslots()}
                orderDays={timeslotFormData.availableOrderDays}
                setSelectedOrderDay={timeslotFormData.setSelectedOrderDay}
                selectedSlot={timeslotFormData.selectedTimeslot}
                setSelectedSlot={timeslotFormData.setSelectedTimeslot}
                orderDayLabel={"Liefertag"} timeslotLabel={"Lieferzeit"}
            />
        )}
        {!addressFormData.isValid && <div>Bitte zuerst die Lieferadresse angeben.</div>}
        <Divider hidden={true}/>
        <NavigationButtonGrid nextButton={<NextButton onClick={next} disabled={!timeslotFormData.isValid || !addressFormData.isValid}/>}/>
      </React.Fragment>
  )

}
